import { useEffect } from "react";

import { Flex, Image } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import { useFetchClaimedProducts } from "flows/Inbound/stores/useInboundDropService";
import Packages from "images/random/packages.svg";
import { BodyM, HeaderS } from "ui/Typography/Typography";

export function InboundClaimedList() {
  const { setInboundUIState, inboundUIState } = useInboundStore.getState();
  const { fetchClaimedProducts } = useFetchClaimedProducts();

  const handleOnline = async () => {
    if (inboundUIState.isRefetchClaimedListConfirmationModalVisible) {
      await fetchClaimedProducts();
      setInboundUIState({ isRefetchClaimedListConfirmationModalVisible: false });
    }
  };
  useEffect(() => {
    window.addEventListener("online", handleOnline);
    return () => {
      window.removeEventListener("online", handleOnline);
    };
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      px="s100"
      gap="s100"
    >
      <HeaderS textAlign="center">
        <FormattedMessage id="flows.inbound.page.inbound-claimed-list.header" />
      </HeaderS>
      <BodyM textAlign="center">
        <FormattedMessage id="flows.inbound.page.inbound-shared-list.paragraph" />
      </BodyM>
      <Image src={Packages} mt="s200" />
    </Flex>
  );
}
