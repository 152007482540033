import { useCallback, useState } from "react";

import { datadogRum } from "@datadog/browser-rum";
import { useNavigate } from "react-router";

import { CollaborativeInboundUpdateState } from "analytics/events";
import { routes } from "config/routes";
import { useFetchUnitsSizesLazyQuery } from "shared/stores/useProductSearchService";

import {
  ListVerificationCheckTypeKeys,
  SerializedListVerificationCheck,
} from "../models/listVerificationCheck/types";
import { useInboundStore } from "../stores/inboundStore/useInboundStore";
import {
  extractInboundPlan,
  selectDespatchAdviceItemBySku,
  sumStockUpdatePlanTotal,
} from "../stores/inboundStore/utils";
import { useInboundEventTracker } from "./useInboundEventTracker/useInboundEventTracker";

export function useGenerateVerificationChecks() {
  const navigate = useNavigate();
  const { sendInboundStateUpdate } = useInboundEventTracker();
  const [isGeneratingChecks, setIsGeneratingChecks] = useState(false);
  const {
    deliveryType,
    deliverySSCC,
    sharedListId,
    inboundUnitsMap,
    verificationChecks,
    inboundUnitsStockUpdates,
    despatchAdviceItems,
    setListVerificationChecks,
    setInboundUIState,
  } = useInboundStore((state) => ({
    deliveryType: state.deliveryType,
    deliverySSCC: state.deliverySSCC,
    sharedListId: state.sharedListId,
    inboundUnitsMap: state.inboundUnitsMap,
    verificationChecks: state.verificationChecks,
    inboundUnitsStockUpdates: state.inboundUnitsStockUpdates,
    despatchAdviceItems: state.despatchAdviceItems,
    setListVerificationChecks: state.setListVerificationChecks,
    setInboundUIState: state.setInboundUIState,
  }));

  const fetchUnitsSizes = useFetchUnitsSizesLazyQuery();

  // Generate verification checks
  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generateChecks = async () => {
    const checkExists = ({
      checkType,
      sku,
      quantityDelta,
    }: {
      checkType: ListVerificationCheckTypeKeys;
      sku: string;
      quantityDelta: number;
    }) => {
      return verificationChecks.some(
        (existingCheck) =>
          existingCheck.sku === sku && existingCheck.key === `${checkType}-${sku}-${quantityDelta}`,
      );
    };

    const getNextMultipleOfHUSize = (quantity: number, handlingUnitSize: number) => {
      return Math.ceil(quantity / handlingUnitSize) * handlingUnitSize;
    };

    let newChecks: SerializedListVerificationCheck[] = [...verificationChecks];
    // Check if all active checks are completed
    const allActiveCompleted = newChecks.every((c) => !c.isActive || c.isCompleted);

    if (allActiveCompleted) {
      // Set isActive to false for all checks if all active checks are completed
      newChecks = newChecks.map((check) => ({
        ...check,
        isActive: false,
      }));
    }
    const skusInStockUpdateState = Object.keys(inboundUnitsStockUpdates);
    const handlingUnitSizes = await fetchUnitsSizes(skusInStockUpdateState, true);

    const addCheck = ({
      checkType,
      sku,
      data,
    }: {
      checkType: ListVerificationCheckTypeKeys;
      sku: string;
      data?: any;
    }) => {
      const quantityDelta = data?.quantity || 1;
      const isExist = checkExists({ checkType, sku, quantityDelta });
      if (!isExist) {
        const existingChecks = newChecks.filter((check) => check.sku !== sku);
        newChecks = [
          ...existingChecks,
          {
            key: `${checkType}-${sku}-${quantityDelta}`,
            sku,
            type: checkType,
            isCompleted: false,
            isActive: true,
            data,
          },
        ];
      }
    };

    // Generate new checks
    skusInStockUpdateState.forEach((sku) => {
      const { stockUpdatePlan, stockUpdated } = inboundUnitsStockUpdates[sku];
      const inboundPlan = extractInboundPlan(stockUpdatePlan);
      if (!inboundPlan || stockUpdated) return;

      const { quantityDelta } = inboundPlan;
      const despatchAdviceItem = selectDespatchAdviceItemBySku(sku, despatchAdviceItems);
      if (despatchAdviceItem) return;

      if (quantityDelta === 1) {
        addCheck({ checkType: ListVerificationCheckTypeKeys.InboundQuantityOfOne, sku });
      } else if (quantityDelta > 50) {
        addCheck({
          checkType: ListVerificationCheckTypeKeys.LargeInboundQuantity,
          sku,
          data: { quantity: quantityDelta },
        });
      } else {
        const handlingUnitSizesForSku = handlingUnitSizes[sku];
        if (handlingUnitSizesForSku?.length) {
          const hasNonDivisibleSize = handlingUnitSizesForSku.every(
            (size) => quantityDelta % size !== 0,
          );
          const stockUpdatePlanTotal = sumStockUpdatePlanTotal(stockUpdatePlan);
          const isOutbounded = stockUpdatePlanTotal === 0;

          if (hasNonDivisibleSize && !isOutbounded) {
            const totalOutboundedQuantity = quantityDelta - stockUpdatePlanTotal;
            const expected =
              handlingUnitSizesForSku.length > 1
                ? undefined
                : getNextMultipleOfHUSize(quantityDelta, handlingUnitSizesForSku[0]) -
                  totalOutboundedQuantity;
            addCheck({
              checkType: ListVerificationCheckTypeKeys.HandlingUnitQuantityMismatch,
              sku,
              data: { quantity: stockUpdatePlanTotal, expected },
            });
          }
        } else {
          newChecks = newChecks.filter((check) => check.sku !== sku);
        }
      }
    });

    return newChecks.filter(({ sku }) => !!inboundUnitsMap[sku]);
  };

  const handleGenerateChecks = useCallback(async () => {
    setIsGeneratingChecks(true);
    setInboundUIState({
      isProductAlreadyAddedBannerVisible: false,
      isQuantityMismatchBannerVisible: false,
    });
    try {
      const newChecks = await generateChecks();
      sendInboundStateUpdate({
        state: CollaborativeInboundUpdateState.ListVerificationStarted,
        droppingListId: sharedListId,
        sscc: deliverySSCC,
        inboundingType: deliveryType,
      });
      setListVerificationChecks(newChecks);
      navigate(routes.inbound.listVerification);
    } catch (_) {
      datadogRum.addError("Failed to generate verification checks.");
    } finally {
      setIsGeneratingChecks(false);
    }
  }, [
    deliverySSCC,
    deliveryType,
    generateChecks,
    navigate,
    sendInboundStateUpdate,
    setInboundUIState,
    setListVerificationChecks,
    sharedListId,
  ]);

  return {
    generateChecks: handleGenerateChecks,
    isGeneratingChecks,
  };
}
