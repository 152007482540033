import { useCallback } from "react";

import { Flex } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { routes } from "config/routes";
import { CorrectionsTextSearch } from "flows/Inventory/shared/components/CorrectionsTextSearch";
import { ScanPlaceholder } from "flows/Inventory/shared/components/ScanPlaceholder";
import { InventoryProduct } from "flows/Inventory/shared/models/inventoryProduct/types";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useScan } from "shared/hooks/useScan/useScan";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { isShelfNumberFormat } from "utils/scan";

import { ProductsList } from "../components/ProductsList";
import { useStockCorrectionService } from "../hooks/useStockCorrectionService";

export function IdlePage() {
  const stockCorrectionService = useStockCorrectionService();
  const intl = useIntl();
  const navigate = useNavigate();

  const isIdle = useSelector(stockCorrectionService, (state) => state.matches("idle"));

  const isResolvingProducts = useSelector(stockCorrectionService, (state) =>
    state.matches("resolvingProducts"),
  );
  const isSearching = useSelector(stockCorrectionService, (state) => state.matches("search"));
  const isSearchLoading = useSelector(stockCorrectionService, (state) =>
    state.matches("search.loading"),
  );
  const searchResults = useSelector(
    stockCorrectionService,
    (state) => state.context.inventoryProductsList,
  );

  const onClickProduct = useCallback((product: InventoryProduct) => {
    stockCorrectionService.send({
      type: "SELECT_INVENTORY_PRODUCT",
      inventoryProductShelf: product.shelf,
      inventoryProductSku: product.productSku,
    });
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = useCallback((query: string) => {
    if (query.length >= 3) {
      stockCorrectionService.send({
        type: "SEARCH_PRODUCTS",
        query,
      });
    } else {
      stockCorrectionService.send({
        type: "RESET_SEARCH_RESULTS",
      });
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancel = useCallback(() => {
    stockCorrectionService.send({
      type: "CANCEL_SEARCH",
    });
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFocusSearchBar = useCallback(() => {
    if (!isSearching) {
      stockCorrectionService.send("ACTIVATE_SEARCH");
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  useScan({
    onScan: (scannedValue) => {
      if (!isIdle) return;

      stockCorrectionService.send({
        type: "SET_SCAN_TYPE",
        scanType: isShelfNumberFormat(scannedValue) ? "shelf" : "product",
      });
      stockCorrectionService.send({ type: "SCAN", barcode: scannedValue });
    },
  });

  const searchResultsList = (
    <ProductsList
      variantSize="s"
      products={searchResults}
      onClickProduct={onClickProduct}
      showStockLabel
    />
  );

  return (
    <Page isFull isBgGrey h="100%" justifyContent="start">
      {!isSearching && (
        <NavigationHeader
          title={intl.formatMessage({ id: "components.inventory.idle.header-title" })}
          onClickGoBack={() => navigate(routes.inventory.root)}
        />
      )}

      <CorrectionsTextSearch
        isLoading={isSearchLoading}
        isSearchActive={isSearching}
        searchResults={searchResults}
        onSearch={onSearch}
        onFocusSearchBar={onFocusSearchBar}
        onCancel={onCancel}
        searchResultsList={searchResultsList}
      />

      {!isSearching && (
        <Flex
          flex={1}
          flexDirection="column"
          w="100%"
          overflowY="scroll"
          alignItems="center"
          justifyContent="center"
          p="s200"
        >
          <ScanPlaceholder
            title="pages.inventory.stock-correction.instructions.title"
            subtitle="pages.inventory.stock-correction.instructions.subtitle"
          />
        </Flex>
      )}
      <SpinnerModal isOpen={isResolvingProducts} />
    </Page>
  );
}
