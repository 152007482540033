import { useEffect, useMemo, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { FormattedMessage } from "react-intl";

import { IntlMessageId } from "shared/types/lang";
import { BodyS, TitleM, TitleS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

const NUMBER_SECONDS_BEFORE_REDIRECT = 5;
const ONE_SECOND_MS = 1000;

export function AuthError() {
  const { error, logout } = useAuth0();

  const [remainingSeconds, setRemainingSeconds] = useState(NUMBER_SECONDS_BEFORE_REDIRECT);

  useEffect(() => {
    let remainingSecondsCount = NUMBER_SECONDS_BEFORE_REDIRECT - 1;
    const interval = setInterval(() => {
      setRemainingSeconds(remainingSecondsCount);
      if (remainingSecondsCount === 0) {
        clearInterval(interval);
        logout({ returnTo: window.location.origin });
        return;
      }
      remainingSecondsCount -= 1;
    }, ONE_SECOND_MS);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorTextId = useMemo(() => {
    let id: IntlMessageId | undefined;
    switch (error?.message) {
      case "This account is not linked to a hub.": {
        id = "pages.auth.auth-error.account-not-linked-error";
        break;
      }
      default:
    }
    return id;
  }, [error?.message]);

  return (
    <>
      <TitleM color="error">
        <FormattedMessage id="pages.auth.auth-error.authentication-error" />
      </TitleM>
      {isNotNullNorUndefined(errorTextId) && (
        <TitleS mt="s200" color="error">
          <FormattedMessage id={errorTextId} />
        </TitleS>
      )}
      <BodyS mt="s150">
        <FormattedMessage
          id="pages.auth.auth-error.remaining-seconds"
          values={{ remainingSeconds }}
        />
      </BodyS>
    </>
  );
}
