import { cloneElement, ReactElement } from "react";

import { Button, Flex, Tag } from "@chakra-ui/react";

import { DetailL, TitleXS } from "ui/Typography/Typography";

interface TabButtonProps {
  onClick: () => void;
  isActive: boolean;
  icon: ReactElement;
  label: string;
  count?: number;
  maxW: number;
  minW: number;
}

export function TabButton({ onClick, isActive, icon, label, count, maxW, minW }: TabButtonProps) {
  return (
    <Button
      variant="unstyled"
      onClick={onClick}
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      gap="s100"
      position="relative"
      maxW={`${maxW}px`}
      minW={`${minW}px`}
      whiteSpace="normal"
      wordBreak="break-word"
      h="36px"
      p="s100"
    >
      <Flex justify="center" align="center" w="24px" h="36px" position="relative" color="gray.800">
        {cloneElement(icon, {
          boxSize: 6,
        })}
        {count ? (
          <Tag
            size="sm"
            minH="16px"
            w="max-content"
            lineHeight="1rem"
            pos="absolute"
            top="6px"
            right="12px"
            transform="translateX(100%)"
            bg="pinkFlink.500"
            color="white"
            borderRadius="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            px="4px"
          >
            {count}
          </Tag>
        ) : null}
      </Flex>
      {isActive ? <TitleXS>{label}</TitleXS> : <DetailL color="grey.800">{label}</DetailL>}
    </Button>
  );
}
