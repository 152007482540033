export enum EppoFeatureFlags {
  WRONG_BARCODE_DETAILS = "hubstaffapp_wrong_barcode_details",
  DEVICE_CLAIMING_PROCESS = "hubstaffapp_device_claiming_process",
  OPS_ASSIGNMENT_V0 = "hubstaffapp_ops_assignment_v0",
  OA_OCCUPATION_REPORTS = "hubstaffapp_ops_assignment_inbounding",
  EOY_CHECKS = "hubstaffapp_enable_eoy_checks",
  EOY_HIDE_CORRECTIONS = "hubstaffapp_eoy_hide_corrections",
  EOY_SHOW_CHECKS = "hubstaffapp_show_eoy_checks",
  PRODUCT_FACING = "hubstaffapp_product_facings",
  LIST_VERIFICATION_HU_CHECK = "hubstaffapp_list_verification_hu_check",
  ROLLI_VALIDATION = "hubstaffapp_rolli_validation",
  INTERCOM = "hubstaffapp_intercom",
  NEW_RESTOCKING = "hubstaffapp_new_restocking",
  CAMPAIGN_PRODUCT = "hubstaffapp_campaign_product",
  COLLABORATIVE_INBOUND = "hubstaffapp_collaborative_inbound",
  COLLABORATIVE_INBOUND_V2 = "hubstaffapp_collaborative_inbound_v2",
  SCAN_JET_REWE_BAG = "hubstaffapp_scan_jet_rewe_bag",
  RESTOCKING_THRESHOLD = "hubstaffapp_restocking_threshold",
}

export enum EppoFeatureVariants {
  ON = "ON",
  OFF = "OFF",
}
