import { useCallback } from "react";

import { useApolloClient } from "@apollo/client";

import { ValidateEoyCheckInput } from "__graphql__/types";
import {
  GetCheckByIdDocument,
  GetCheckByIdQuery,
  GetCheckByIdQueryVariables,
} from "flows/Inventory/shared/queries/check/check.generated";

import {
  ValidateEoyCheckDocument,
  ValidateEoyCheckMutation,
  ValidateEoyCheckMutationVariables,
} from "../queries/eoyCheck/eoyCheck.generated";

export const useEoyChecksQueries = () => {
  const apollo = useApolloClient();

  const validateEoyCheckMutation = useCallback(
    ({ checkId, quantity, type, name }: ValidateEoyCheckInput) => {
      return apollo.mutate<ValidateEoyCheckMutation, ValidateEoyCheckMutationVariables>({
        mutation: ValidateEoyCheckDocument,
        variables: { input: { checkId, quantity, type, name } },
      });
    },
    [apollo],
  );

  const fetchCheckByIdQuery = useCallback(
    ({ checkId }: { checkId: string }) => {
      return apollo.query<GetCheckByIdQuery, GetCheckByIdQueryVariables>({
        query: GetCheckByIdDocument,
        variables: { checkId, isAlwaysInbound: true },
        fetchPolicy: "network-only",
      });
    },
    [apollo],
  );

  return { validateEoyCheckMutation, fetchCheckByIdQuery };
};
