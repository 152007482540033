import { useCallback, useEffect, useState } from "react";

import { Box, Flex } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router";
import { generatePath } from "react-router-dom";
import shallow from "zustand/shallow";

import { PageName } from "analytics/events";
import { routes } from "config/routes";
import { Page } from "shared/components/Page";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useCountUpTimer } from "shared/hooks/useCountUpTimer/useCountUpTimer";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { Button } from "ui/Button/Button";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { BodyS, HeaderS, TitleM } from "ui/Typography/Typography";
import { formatDuration } from "utils/formats";
import { isNotNullNorUndefined, isNullOrUndefined } from "utils/tsHelpers";

import { timedActivities } from "../../models/timedActivity/config";

export function TimedActivitiesDetails() {
  const { activityId: activityID } = useParams<{ activityId: string }>();

  useEffect(() => {
    window.history.pushState(null, "", routes.activities.root);
    window.history.pushState(null, "", routes.activities.timedActivities.list);
    window.history.pushState(
      null,
      "",
      generatePath(routes.activities.timedActivities.details, { activityId: activityID }),
    );
  }, [activityID]);

  const intl = useIntl();
  const navigate = useNavigate();
  const { sendSegmentTrackEvent } = useAnalytics();

  const { stopTimedActivity, startTimedActivity, timedActivityInstance } = useTimedActivityStore(
    (state) => {
      return {
        stopTimedActivity: state.stopTimedActivity,
        startTimedActivity: state.startTimedActivity,
        timedActivityInstance: state.timedActivityInstance,
      };
    },
    shallow,
  );

  const [isFinishButtonDisabled, setIsFinishedButtonDisabled] = useState(
    isNullOrUndefined(timedActivityInstance) && isNotNullNorUndefined(activityID),
  );

  const timedActivity = isNullOrUndefined(activityID)
    ? null
    : timedActivities.find((a) => a.id === activityID);

  const isActivityOngoing =
    isNotNullNorUndefined(timedActivityInstance) && timedActivityInstance.activityID === activityID;

  const { startTimer, elapsedTimeInMs } = useCountUpTimer();

  const goBackToActivitiesList = useCallback(() => {
    navigate(routes.activities.timedActivities.list);
  }, [navigate]);

  useEffect(() => {
    if (isActivityOngoing) {
      startTimer(timedActivityInstance.startTime);
    } else if (isNotNullNorUndefined(timedActivityInstance)) {
      // when the user somehow ends up on the details page of an activity
      // that is not the currently active activity, we redirect them back to the list page
      goBackToActivitiesList();
    }
  }, [
    isActivityOngoing,
    startTimer,
    timedActivityInstance?.startTime,
    timedActivityInstance,
    goBackToActivitiesList,
  ]);

  useEffect(() => {
    if (isNullOrUndefined(timedActivityInstance) && isNotNullNorUndefined(activityID)) {
      const activityInstanceId = startTimedActivity(activityID);
      if (isNullOrUndefined(activityInstanceId)) {
        return;
      }
      setTimeout(() => {
        setIsFinishedButtonDisabled(false);
      }, 10000);
      sendSegmentTrackEvent("hubActivityStarted", {
        screen_name: PageName.HUB_ACTIVITIES,
        category_type: activityID,
        hub_activity_id: activityInstanceId,
      });
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stopActivityAndGoBack = useCallback(() => {
    stopTimedActivity();
    if (isNotNullNorUndefined(timedActivityInstance)) {
      sendSegmentTrackEvent("hubActivityFinished", {
        screen_name: PageName.HUB_ACTIVITIES,
        category_type: timedActivityInstance.activityID,
        hub_activity_id: timedActivityInstance.activityInstanceID,
        origin: "activity_flow",
        task_completion_time_seconds: Math.floor(
          (Date.now() - timedActivityInstance.startTime.getTime()) / 1000,
        ),
      });
    }
    goBackToActivitiesList();
  }, [stopTimedActivity, goBackToActivitiesList, sendSegmentTrackEvent, timedActivityInstance]);

  return (
    <Page isFull isCentered bgColor="grey.100">
      <NavigationHeader
        title={
          isNotNullNorUndefined(timedActivity)
            ? intl.formatMessage({ id: timedActivity.nameKey })
            : ""
        }
        onClickGoBack={goBackToActivitiesList}
      />
      <Box flex={1} w="100%">
        {timedActivity && (
          <Flex
            mx="s200"
            mt="s300"
            p="s300"
            gap="s300"
            bgColor="white"
            borderRadius="sm"
            direction="column"
            align="center"
            textAlign="center"
          >
            <TitleM>
              <FormattedMessage id={timedActivity.nameKey} />
            </TitleM>
            <BodyS>
              <FormattedMessage id={timedActivity.descriptionKey} />
            </BodyS>
            <HeaderS data-testid="timed-activity-timer">{formatDuration(elapsedTimeInMs)}</HeaderS>
            <Button
              w="7.5rem"
              h="3rem"
              disabled={isFinishButtonDisabled}
              onClick={stopActivityAndGoBack}
              data-testid="timed-activity-finish-button"
            >
              <FormattedMessage id="flows.activities.timed-activities.finish-button-label" />
            </Button>
          </Flex>
        )}
      </Box>
    </Page>
  );
}
