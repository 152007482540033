import { useCallback } from "react";

import { useIntl } from "react-intl";

import { usePickingService } from "flows/Picking/hooks/usePickingService";
import CampaignLogo from "images/random/campaign.png";

import { useCampaignQuantites } from "../hooks/usePickingMachineSelectors";
import { PickingProductCard } from "./PickingProductCard";

export function Campaign() {
  const intl = useIntl();

  const { send } = usePickingService();
  const { picked, total } = useCampaignQuantites();
  const handleClickProductCard = useCallback(() => {
    if (picked === total) {
      return;
    }
    send({
      type: "INCREMENT_CAMPAIGN_QUANTITY",
    });
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picked, total]);

  return (
    <PickingProductCard
      itemId="itemId"
      imageUrl={CampaignLogo}
      name={intl.formatMessage({ id: "components.picking.campaign.product-card-title" })}
      pickedQuantity={picked}
      totalQuantity={total}
      hasBarcode={false}
      state={picked === total ? "DONE" : "IDLE"}
      onClickProductCard={handleClickProductCard}
    />
  );
}
