import { useCallback, useEffect, useState } from "react";

import { useIntl } from "react-intl";

import { PageName } from "analytics/events";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { ErrorTypeForAnalytics, useAnalytics } from "shared/hooks/useAnalytics";
import { ConnectivityBanner as ConnectivityBannerUI } from "ui/ConnectivityBanner/ConnectivityBanner";

export function ConnectivityBanner() {
  const intl = useIntl();

  const { sendSegmentTrackEvent } = useAnalytics();
  const withOfflineBanner = useLayoutStore((state) => state.withOfflineBanner);
  const [isOnline, setIsOnline] = useState(true);

  const handleOnline = useCallback(() => {
    setIsOnline(true);
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOffline = useCallback(() => {
    sendSegmentTrackEvent("errorShown", {
      screen_name: PageName.LAYOUT,
      component_value: ErrorTypeForAnalytics.CONNECTION_LOST,
    });
    setIsOnline(false);
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isOnline || !withOfflineBanner) {
    return null;
  }

  return (
    <ConnectivityBannerUI
      title={intl.formatMessage({ id: "components.connectivity-banner.title" })}
    />
  );
}
