import { MouseEvent } from "react";

import { Box, BoxProps, Flex } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { EppoFeatureFlags } from "core/types/flags";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { NewScanningIcon, NewTapIcon, WarningThreeIcon } from "ui/Icons/Icons";
import { PickingProductCardCounter } from "ui/PickingProductCardCounter/PickingProductCardCounter";
import { ProductInformation } from "ui/ProductInformation/ProductInformation";
import { Shelf } from "ui/Shelf/Shelf";
import { Status } from "ui/Status/Status";
import { TitleXS } from "ui/Typography/Typography";
import { preventPropagation } from "utils/domEvents";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { ProductState } from "../types/productState";
import { AskForRestockingButton } from "./AskForRestockingButton/AskForRestockingButton";
import { PickingProductCardStatusTags } from "./PickingProductCardStatusTags";

export const PRODUCT_CARD_IMAGE_WIDTH_NUMBER = 93;

export type PickingProductCardProps = {
  state: ProductState;
  itemId: string;
  elementRef?: (element: HTMLDivElement) => void;
  pickedQuantity: number;
  totalQuantity: number;
  hasBarcode: boolean;
  shelfNumber?: string | null;
  shelfLetter?: string | null;
  name?: string | null;
  sku?: string | null;
  imageUrl?: string | null;
  showSKU?: boolean;
  resetButtonLabel?: string;
  onResetClick?: () => void;
  onBarcodeClick?: () => void;
  isSkippedItem?: boolean;
  hasMissingItems?: boolean;
  skippedItemPendingStatusLabel?: string;
  skippedItemRefundStatusLabel?: string;
  skippedItemBackStockLabel?: string;
  skipButtonLabel?: string;
  onSkipItemClick?: () => void;
  itemNotFoundButtonLabel?: string;
  onItemNotFoundClick?: () => void;
  onClickProductCard?: (event: MouseEvent) => void;
  countryOfOriginName?: string | null;
  availableQuantity?: number;
  handlingUnitSize?: number | null;
  onAskForRestocking?: () => void;
  shouldShowRestockingDetails?: boolean;
  isAskRestockingButtonDisabled?: boolean;
  onIncrementItemQuantityClick?: () => void;
  onDecrementItemQuantityClick?: () => void;
  isCrossVisible?: boolean;
} & BoxProps;

export function PickingProductCard({
  imageUrl,
  itemId,
  name,
  sku,
  shelfLetter,
  shelfNumber,
  hasBarcode = true,
  pickedQuantity,
  totalQuantity,
  skipButtonLabel,
  resetButtonLabel,
  state,
  showSKU = true,
  onBarcodeClick,
  onSkipItemClick,
  elementRef,
  onResetClick,
  isSkippedItem = false,
  hasMissingItems = false,
  skippedItemPendingStatusLabel,
  skippedItemRefundStatusLabel,
  itemNotFoundButtonLabel,
  onItemNotFoundClick,
  skippedItemBackStockLabel,
  onClickProductCard,
  countryOfOriginName,
  availableQuantity,
  handlingUnitSize,
  onAskForRestocking,
  shouldShowRestockingDetails = false,
  isAskRestockingButtonDisabled,
  onIncrementItemQuantityClick,
  onDecrementItemQuantityClick,
  isCrossVisible = false,
  ...rest
}: PickingProductCardProps) {
  // eslint-disable-next-line no-nested-ternary
  const sidebarColor = !hasMissingItems ? "veggie.500" : isSkippedItem ? "orange.500" : "red.500";
  const sidebarProps: BoxProps = { bg: sidebarColor };
  const intl = useIntl();

  const { variation } = useEppoFeatureFlagProvider(EppoFeatureFlags.RESTOCKING_THRESHOLD);
  // Ensure a valid handling unit size, defaulting to 3 if undefined or null
  const defaultThreshold = isNotNullNorUndefined(handlingUnitSize)
    ? Math.floor(handlingUnitSize / 2)
    : 3;
  // Convert variation to number once and store it
  const variationAsNumber = Number(variation);
  // Use the converted value if it's a valid number, otherwise fallback to defaultThreshold
  const threshold = Number.isNaN(variationAsNumber) ? defaultThreshold : variationAsNumber;
  // Calculate the remaining quantity, defaulting to 0 if unavailable
  const remainingQuantity = (availableQuantity ?? 0) - totalQuantity;
  // Determine if the warning should be shown based on remaining quantity and threshold
  const isLastItemsWarningVisible = remainingQuantity <= threshold;

  const selectedIcon = hasBarcode ? (
    <NewScanningIcon
      boxSize="35px"
      onClick={preventPropagation(onBarcodeClick)}
      data-testid="icon-scan"
    />
  ) : (
    <NewTapIcon data-testid="icon-touch" boxSize="35px" />
  );
  const actionIcon = pickedQuantity > 0 ? null : selectedIcon;

  return (
    <Box
      id={itemId}
      p="s200"
      w="100%"
      position="relative"
      bg="white"
      ref={elementRef}
      onClick={onClickProductCard}
      data-testid="product-card"
      {...rest}
    >
      <Box display="flex" justifyContent="space-between" ml="auto">
        <PickingProductCardStatusTags
          state={state}
          isSkippedItem={isSkippedItem}
          hasMissingItems={hasMissingItems}
          skippedItemPendingStatusLabel={skippedItemPendingStatusLabel}
          skippedItemRefundStatusLabel={skippedItemRefundStatusLabel}
          skippedItemBackStockLabel={skippedItemBackStockLabel}
        />
        {countryOfOriginName && (
          <Status
            size="small"
            minWidth="max-content"
            label={countryOfOriginName}
            bg="grey.100"
            whiteSpace="nowrap"
          />
        )}
        <Flex justifyContent="flex-end" width="100%">
          {isNotNullNorUndefined(shelfLetter) && isNotNullNorUndefined(shelfNumber) && (
            <Shelf letter={shelfLetter} number={shelfNumber} />
          )}
        </Flex>
      </Box>
      <ProductInformation
        name={name ?? ""}
        imageUrl={imageUrl}
        sku={sku ?? ""}
        variantSize="m"
        bg="grey.100"
        borderRadius="sm"
        pr="s0"
        pl="s0"
        my="s200"
        showLeftSideBar={state === "DONE"}
        leftSideBarProps={sidebarProps}
        stock={showSKU ? undefined : availableQuantity}
        showSKU={showSKU}
        isCrossVisible={isCrossVisible}
      />
      <Flex align="center" justify="space-between">
        <Flex justifyContent="flex-start">
          <PickingProductCardCounter
            state={state}
            count={pickedQuantity}
            total={totalQuantity}
            hasError={hasMissingItems}
            isSkippedItem={isSkippedItem}
            onIncrementItemQuantityClick={onIncrementItemQuantityClick}
            onDecrementItemQuantityClick={onDecrementItemQuantityClick}
            mr="s200"
            h="40px"
            w="60px"
          />
          {actionIcon}
        </Flex>
        {shouldShowRestockingDetails && (
          <Flex justifyContent="flex-end">
            {isLastItemsWarningVisible ? (
              <Flex align="center" color="red.500">
                <WarningThreeIcon boxSize="24px" />
                <TitleXS ml="1.5" color="red.500">
                  {intl.formatMessage({ id: "components.picking.restocking.last-items-available" })}
                </TitleXS>
              </Flex>
            ) : (
              <AskForRestockingButton
                disabled={isAskRestockingButtonDisabled}
                onAskForRestocking={onAskForRestocking}
              />
            )}
          </Flex>
        )}
      </Flex>
    </Box>
  );
}
