import { useCallback } from "react";

import { useNavigate } from "react-router";

import { CollaborativeInboundUpdateState } from "analytics/events";
import { routes } from "config/routes";
import { EndDroppingProcessButton } from "flows/Inbound/components/EndDroppingProcessButton";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";

import { useInboundEventTracker } from "../hooks/useInboundEventTracker/useInboundEventTracker";
import { useInboundStore } from "../stores/inboundStore/useInboundStore";

export function InboundEndDroppingButton() {
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();
  const { sendInboundStateUpdate } = useInboundEventTracker();
  const resetToInitialState = useInboundStore((state) => state.resetToInitialState);
  const onClickFinishDropping = useCallback(() => {
    showToastUI({
      title: "pages.inbound.inbound-dropping-list.finish-dropping-success-toast-title",
      status: "success",
      variant: "success",
      duration: 2000,
      containerStyle: {
        width: "100%",
        bottom: BOTTOM_TAB_BAR_HEIGHT_NUMBER,
        position: "absolute",
        paddingLeft: "s200",
        paddingRight: "s200",
      },
    });
    const { droppingListId, deliverySSCC, deliveryType } = useInboundStore.getState();
    sendInboundStateUpdate({
      state: CollaborativeInboundUpdateState.DroppingListFinished,
      droppingListId,
      sscc: deliverySSCC,
      inboundingType: deliveryType,
    });
    resetToInitialState();
    navigate(routes.inbound.root);
  }, [navigate, resetToInitialState, sendInboundStateUpdate, showToastUI]);

  return (
    <EndDroppingProcessButton
      shouldShowButton
      labelMessageId="pages.inbound.inbound-dropping-list.finish-dropping-button-label"
      onClickButton={onClickFinishDropping}
      buttonTestId="finish-dropping-button"
      bottom={0}
    />
  );
}
