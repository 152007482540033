import { useCallback, useEffect, useRef, useMemo, useState } from "react";

import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import shallow from "zustand/shallow";

import { CollaborativeInboundUpdateState } from "analytics/events";
import { routes } from "config/routes";
import { HEIGHT } from "core/components/Header";
import { useHandleSharedListNameUpdate } from "core/hooks/useHandleSharedListNameUpdate";
import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import { EppoFeatureFlags } from "core/types/flags";
import { AddItemsToSharedListButton } from "flows/Inbound/components/AddItemsToSharedListButton";
import { DespatchAdviceInfoBanner } from "flows/Inbound/components/DespatchAdviceInfoBanner";
import { HUSizeQuantityMismatchBanner } from "flows/Inbound/components/HUSizeQuantityMismatchBanner";
import { InboundIndexInstructions } from "flows/Inbound/components/InboundIndexInstructions";
import { PreDroppingListPreparation } from "flows/Inbound/components/PreDroppingListPreparation";
import { ProductAlreadyAddedBanner } from "flows/Inbound/components/ProductAlreadyAddedBanner";
import { ProductsSearchComponent } from "flows/Inbound/components/ProductsSearchComponent";
import { ScanningFailedModal } from "flows/Inbound/components/ScanningFailedModal";
import { SharedListCreationConfirmationModal } from "flows/Inbound/components/SharedListCreationConfirmationModal";
import { SharedListUnsavedChangesWarningModal } from "flows/Inbound/components/SharedListUnsavedChangesWarningModal";
import { SharedListVerificationButton } from "flows/Inbound/components/SharedListVerificationButton";
import { ShareListWarningModal } from "flows/Inbound/components/ShareListWarningModal";
import { SharingPreDropListButton } from "flows/Inbound/components/SharingPreDropListButton";
import { UnverifiedQuantityModal } from "flows/Inbound/components/UnverifiedQuantityModal";
import { useInboundEventTracker } from "flows/Inbound/hooks/useInboundEventTracker/useInboundEventTracker";
import useScrollDetection from "flows/Inbound/hooks/useScrollDetection";
import {
  DEFAULT_LETTER_RANGE_ID,
  useInboundStore,
} from "flows/Inbound/stores/inboundStore/useInboundStore";
import { useSharedListCreator } from "flows/Inbound/stores/useSharedListCreator";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useScan } from "shared/hooks/useScan/useScan";
import {
  useFetchInboundPreDroppingProducts,
  useFetchProductsByEan,
} from "shared/stores/useProductSearchService";
import {
  ProductSearchActionTypes,
  useProductSearchStore,
} from "shared/stores/useProductSearchStore";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { CheckIcon, FilledPencilIcon, ThreedotsIcon } from "ui/Icons/Icons";
import { NavigationHeaderWithInput } from "ui/NavigationHeaderWithInput/NavigationHeaderWithInput";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export function PreDroppingListPage() {
  const navigate = useNavigate();
  const preDroppingListRef = useRef<HTMLDivElement | null>(null);
  const { sendInboundStateUpdate } = useInboundEventTracker();
  const isPreDroppingListScrolling = useScrollDetection(preDroppingListRef);
  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);
  const { isAddingItems, isSharingList, handleAddItemsModalSave, handleAddItemsModalCancel } =
    useSharedListCreator();
  const { isLoadingPreDroppingProducts } = useFetchInboundPreDroppingProducts();
  const { onScan, isScannedProductLoading } = useFetchProductsByEan("inbounding");
  const { newListName, setNewListName, handleUpdateSharedListName } =
    useHandleSharedListNameUpdate();
  const setSearchState = useProductSearchStore((state) => state.setSearchState);
  const [nextLocation, setNextLocation] = useState(routes.inbound.root);
  const { isFeatureEnabled: isCollaborativeInboundV2Enabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.COLLABORATIVE_INBOUND_V2,
  );

  const {
    sharedListName,
    sharedListId,
    deliverySSCC,
    deliveryType,
    inboundUnitsSortedByDate,
    skusWithUnconfirmedQuantities,
    inboundUnitsStockUpdates,
    inboundUIState,
    setInboundUIState,
    persistPreDroppingState,
    resetToInitialState,
  } = useInboundStore(
    (state) => ({
      sharedListName: state.sharedListName,
      sharedListId: state.sharedListId,
      deliverySSCC: state.deliverySSCC,
      deliveryType: state.deliveryType,
      inboundUnitsSortedByDate: state.inboundUnitsSortedByDate,
      skusWithUnconfirmedQuantities: state.skusWithUnconfirmedQuantities,
      inboundUnitsStockUpdates: state.inboundUnitsStockUpdates,
      inboundUIState: state.inboundUIState,
      setInboundUIState: state.setInboundUIState,
      persistPreDroppingState: state.persistPreDroppingState,
      resetToInitialState: state.resetToInitialState,
    }),
    shallow,
  );

  const {
    isListValidationActive,
    isQuantityEditActive,
    isScanningFailedModalVisible,
    isDespatchAdviceInfoBannerVisible,
    isProductAlreadyAddedBannerVisible,
    isUnverifiedQuantityModalVisible,
    isUnsavedChangesWarningModalVisible,
    isShareListWarningModalVisible,
    isQuantityMismatchBannerVisible,
    isSharedListNameEditActive,
  } = inboundUIState;
  const hasUnconfirmedQuantities = skusWithUnconfirmedQuantities.length > 0;
  const hasAllZeroQuantities = Object.values(inboundUnitsStockUpdates).every((stockItem) =>
    stockItem.stockUpdatePlan.every((plan) => plan.quantityDelta === 0),
  );
  const preDroppingListIsEmpty = inboundUnitsSortedByDate.length === 0 || hasAllZeroQuantities;
  const shouldShowDespatchAdviceInfoBanner =
    hasUnconfirmedQuantities && isDespatchAdviceInfoBannerVisible;
  const shouldShowProductSearchBar = preDroppingListIsEmpty || !isQuantityEditActive;

  const { showValidationButton, showAddItemsButton } = useMemo(() => {
    const shouldHideButtons =
      hasUnconfirmedQuantities ||
      inboundUnitsSortedByDate.length === 0 ||
      isQuantityEditActive ||
      isPreDroppingListScrolling;

    if (shouldHideButtons) {
      return { showValidationButton: false, showAddItemsButton: false };
    }
    if (isListValidationActive && !hasAllZeroQuantities) {
      return { showValidationButton: true, showAddItemsButton: false };
    }
    return { showValidationButton: false, showAddItemsButton: true };
  }, [
    hasUnconfirmedQuantities,
    inboundUnitsSortedByDate.length,
    isQuantityEditActive,
    isPreDroppingListScrolling,
    isListValidationActive,
    hasAllZeroQuantities,
  ]);

  useEffect(() => {
    const keyboard = (navigator as any).virtualKeyboard;
    if (!keyboard) {
      return () => {};
    }
    const defaultKeyboardState = keyboard.overlaysContent;
    keyboard.overlaysContent = true;
    return () => {
      keyboard.overlaysContent = defaultKeyboardState;
    };
  }, []);

  useEffect(() => {
    if (!sharedListName) navigate(routes.inbound.root);
  }, [navigate, sharedListName]);

  const activateSearchFlow = useCallback(() => {
    if (hasUnconfirmedQuantities) {
      setInboundUIState({ isUnverifiedQuantityModalVisible: true });
      return;
    }
    if (isNotNullNorUndefined(timedActivityInstance)) {
      openOngoingActivityModal();
      return;
    }
    setInboundUIState({ currentLetterRangeId: DEFAULT_LETTER_RANGE_ID });
    setSearchState(ProductSearchActionTypes.ACTIVATE_SEARCH);
    navigate(routes.inbound.manualSearch);
  }, [
    hasUnconfirmedQuantities,
    navigate,
    setInboundUIState,
    setSearchState,
    timedActivityInstance,
  ]);

  useScan({ onScan });
  const blockerFunction = (args: any) => {
    if (!preDroppingListIsEmpty && args.nextLocation.pathname !== args.currentLocation.pathname) {
      setNextLocation(args.nextLocation.pathname);
      const isBlockedPath =
        args.nextLocation.pathname !== routes.inbound.manualSearch &&
        args.nextLocation.pathname !== routes.inbound.listVerification &&
        args.nextLocation.pathname !== routes.inbound.outbound;
      if (isBlockedPath) {
        return true;
      }
    }
    return false;
  };
  const blocker = useBlocker(blockerFunction);

  const sharedListUnsavedChangesWarningModalBlockerYes = () => {
    sendInboundStateUpdate({
      state: CollaborativeInboundUpdateState.ListPreparationPaused,
      droppingListId: sharedListId,
      sscc: deliverySSCC,
      inboundingType: deliveryType,
    });
    if (isCollaborativeInboundV2Enabled) {
      blocker.proceed?.();
      persistPreDroppingState();
      setInboundUIState({ isShareListWarningModalVisible: false });
      navigate(nextLocation);
    } else {
      blocker.proceed?.();
      resetToInitialState();
      setInboundUIState({ isUnsavedChangesWarningModalVisible: false });
      navigate(nextLocation);
    }
  };

  if (!sharedListName) return null;

  const rightElement = isSharedListNameEditActive ? (
    <CheckIcon width="24px" height="24px" cursor="pointer" onClick={handleUpdateSharedListName} />
  ) : (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<ThreedotsIcon boxSize="24px" />}
        variant="unstyled"
        aria-label="Options"
        w="24px"
        h="24px"
        textAlign="end"
      />
      <MenuList
        zIndex={50}
        minWidth="140px"
        borderRadius="sm"
        width="auto"
        mt="-45px"
        boxShadow="lg"
      >
        <MenuItem
          icon={<FilledPencilIcon boxSize={4} />}
          sx={{
            _hover: {
              bg: "transparent",
            },
          }}
          paddingY="s150"
          onClick={() => {
            setInboundUIState({ isSharedListNameEditActive: true });
          }}
        >
          <FormattedMessage id="flows.inbound.components.shared-list-component.menu-edit" />
        </MenuItem>
      </MenuList>
    </Menu>
  );

  return (
    <Page isFull isBgGrey h="100%">
      <NavigationHeaderWithInput
        title={newListName}
        onClickGoBack={() => {
          if (!preDroppingListIsEmpty) {
            setInboundUIState(
              isCollaborativeInboundV2Enabled
                ? { isShareListWarningModalVisible: true }
                : { isUnsavedChangesWarningModalVisible: true },
            );
          } else {
            resetToInitialState();
            navigate(routes.inbound.root);
          }
        }}
        onTitleChange={(value) => {
          setNewListName(value);
        }}
        isDisabled={!isSharedListNameEditActive}
        showNavigationArrow={shouldShowProductSearchBar}
        rightElement={rightElement}
      />
      {isProductAlreadyAddedBannerVisible && (
        <ProductAlreadyAddedBanner
          onClose={() => {
            setInboundUIState({ isProductAlreadyAddedBannerVisible: false });
          }}
        />
      )}
      {isQuantityMismatchBannerVisible && (
        <HUSizeQuantityMismatchBanner
          onClose={() => setInboundUIState({ isQuantityMismatchBannerVisible: false })}
        />
      )}
      {shouldShowDespatchAdviceInfoBanner && <DespatchAdviceInfoBanner />}
      {shouldShowProductSearchBar && (
        <ProductsSearchComponent
          isSearchFlowActive={false}
          activateTextSearch={activateSearchFlow}
        />
      )}
      {inboundUnitsSortedByDate.length === 0 ? (
        <Flex
          flex={1}
          w="100%"
          h="100%"
          maxH={`calc(100% - ${HEIGHT})`}
          align="center"
          justify="center"
          p="s200"
        >
          <InboundIndexInstructions />
        </Flex>
      ) : (
        <Flex
          ref={preDroppingListRef}
          direction="column"
          flex={1}
          w="100%"
          h="100%"
          maxH={shouldShowProductSearchBar ? "calc(100% - 129px)" : "100%"}
          justify="flex-start"
          bg="grey.100"
          overflowX="scroll"
          paddingTop={shouldShowDespatchAdviceInfoBanner ? HEIGHT : undefined}
        >
          <PreDroppingListPreparation />
          {showValidationButton && <SharedListVerificationButton />}
          {showAddItemsButton &&
            (isCollaborativeInboundV2Enabled ? (
              <SharingPreDropListButton />
            ) : (
              <AddItemsToSharedListButton hasAllZeroQuantities={hasAllZeroQuantities} />
            ))}
        </Flex>
      )}
      <ScanningFailedModal
        isOpen={isScanningFailedModalVisible}
        onClickConfirm={() => setInboundUIState({ isScanningFailedModalVisible: false })}
      />
      <UnverifiedQuantityModal
        isOpen={isUnverifiedQuantityModalVisible}
        onVerifyQuantity={() => {
          setInboundUIState({ isUnverifiedQuantityModalVisible: false });
        }}
      />
      {isCollaborativeInboundV2Enabled ? (
        <ShareListWarningModal
          isOpen={blocker.state === "blocked" || isShareListWarningModalVisible}
          onClickYes={() => sharedListUnsavedChangesWarningModalBlockerYes()}
          onClickNo={() => {
            blocker.reset?.();
            setInboundUIState({ isShareListWarningModalVisible: false });
          }}
        />
      ) : (
        <>
          <SharedListCreationConfirmationModal
            isOpen={inboundUIState.isAddToSharedListModalVisible}
            onClickYes={handleAddItemsModalSave}
            onClickNo={handleAddItemsModalCancel}
          />
          <SharedListUnsavedChangesWarningModal
            isOpen={blocker.state === "blocked" || isUnsavedChangesWarningModalVisible}
            onClickYes={() => sharedListUnsavedChangesWarningModalBlockerYes()}
            onClickNo={() => {
              blocker.reset?.();
              setInboundUIState({ isUnsavedChangesWarningModalVisible: false });
            }}
          />
        </>
      )}
      <SpinnerModal
        isOpen={
          isAddingItems || isSharingList || isScannedProductLoading || isLoadingPreDroppingProducts
        }
      />
    </Page>
  );
}
