import { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { useAppBackgroundEffectsStore } from "core/stores/useAppBackgroundEffectsStore";
import { AudioSound, useAudio } from "shared/hooks/useAudio";

// The arg "shouldStopPlaying" is used by the parent component to force pause the audio playbacks
export function useWaitingOrdersSound(shouldStopPlaying = false) {
  const { play, pause } = useAudio(AudioSound.newOrder, true);
  const { isAuthenticated } = useAuth0();

  const hasNextOrderForPicking = useAppBackgroundEffectsStore(
    (state) => !!state.summaryOfNextOrderForPicking,
  );

  useEffect(() => {
    if (!hasNextOrderForPicking || shouldStopPlaying || !isAuthenticated) {
      // If there are either no order for picking or the parent component required
      // the audio to stop playing : we stop the audio
      pause();
      return;
    }
    // Else if there is an order for picking AND the parent component allows
    // the audio to play : we play the audio
    play();
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextOrderForPicking, shouldStopPlaying]);
}
