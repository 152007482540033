import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useLayoutStore } from "core/stores/useLayoutStore";
import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { QuantityFieldProps } from "ui/InboundPreDroppingListItemCard/InboundPreDroppingListItemCard";
import { debounce } from "utils/debounce";
import { preventScroll } from "utils/domEvents";

import { useInboundStore } from "../stores/inboundStore/useInboundStore";

type UseQuantityInputFieldProps = {
  cardRef: React.MutableRefObject<HTMLDivElement | undefined>;
  scrollContainerRef: React.RefObject<HTMLElement | null>;
  defaultTabBarState: boolean;
  allowBlur: boolean;
  setValue: ({ oldValue, newValue }: { oldValue: number; newValue: number }) => number;
  value: number;
  onSubmit?: () => void;
  uiEffects?: { effect: () => void; cleanup: () => void };
  applyValidationTransform?: (rawValue: string) => string | null; // returning null means the change will be rejected
};

export function useQuantityInputField({
  cardRef,
  scrollContainerRef,
  defaultTabBarState,
  allowBlur,
  setValue,
  value,
  onSubmit = () => {},
  uiEffects,
  applyValidationTransform = (x) => x,
}: UseQuantityInputFieldProps): {
  fieldProps: QuantityFieldProps;
  focus: HTMLOrSVGElement["focus"];
  blur: HTMLOrSVGElement["blur"];
  resetInputValue: () => void;
} {
  // IMPORTED VALUES

  const setInboundUIStore = useInboundUIStore((state) => state.setInboundUIState);
  const setInboundUIState = useInboundStore((state) => state.setInboundUIState);

  const setAppLayout = useLayoutStore((state) => state.setAppLayout);

  // LOCAL STATE

  const [displayValue, setDisplayValue] = useState(value.toString());
  const [isEditing, setIsEditing] = useState(false);

  const blurringFromSubmitEvent = useRef(false);
  const inputRef = useRef<HTMLInputElement | undefined>();
  const fastTypeCounter = useRef(0);
  const inputContentBeforeFastTyping = useRef(displayValue);
  const oldInputQuantityRef = useRef(value);
  const allowFocus = useRef(false);
  const forceBlur = useRef(false);

  // CALLBACKS

  const handleDisplayValueChange = useCallback(
    (newQuantity: string): boolean => {
      const isOnlyDigits = /^\d+$/;
      const isOnlyZeros = /^0+$/;
      if (
        (newQuantity.length > 0 && !isOnlyDigits.test(newQuantity)) ||
        isOnlyZeros.test(newQuantity)
      ) {
        return false;
      }
      const limitedNewQuantity = newQuantity.substring(0, 4);
      let newDisplayedValue = "";
      let newDisplayValueAsNumber = 1;
      if (limitedNewQuantity.length > 0) {
        newDisplayValueAsNumber = parseInt(limitedNewQuantity, 10);
        newDisplayedValue = newDisplayValueAsNumber.toString();
      }

      setDisplayValue(newDisplayedValue);
      return true;
    },
    [setDisplayValue],
  );

  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetFastTypeCounterWhenIdle = useCallback(
    debounce(() => {
      if (fastTypeCounter.current >= 3) {
        handleDisplayValueChange(inputContentBeforeFastTyping.current);
      }
      fastTypeCounter.current = 0;
    }, 10),
    [],
  );

  const setValueAndResetOldValue = useCallback(
    (newValue: number) => {
      oldInputQuantityRef.current = setValue({ oldValue: oldInputQuantityRef.current, newValue });
    },
    [setValue],
  );

  const submitQuantityChange = useCallback(() => {
    const rawValue = inputRef.current?.value ?? "";
    const newValue = applyValidationTransform(rawValue) ?? value.toString();
    if (rawValue !== newValue) {
      handleDisplayValueChange(newValue);
    }
    const newValueAsNumber = parseInt(newValue, 10);
    setValueAndResetOldValue(newValueAsNumber);
    onSubmit();
  }, [
    value,
    applyValidationTransform,
    handleDisplayValueChange,
    setValueAndResetOldValue,
    onSubmit,
  ]);

  const executeUIEffects = useCallback(() => {
    setInboundUIStore({ isEditingQuantity: true });
    setInboundUIState({ isQuantityEditActive: true });
    setAppLayout({ withTabBar: false });
    scrollContainerRef.current?.addEventListener("wheel", preventScroll, { passive: false });
    scrollContainerRef.current?.addEventListener("touchmove", preventScroll, { passive: false });
    uiEffects?.effect();
  }, [setInboundUIStore, setInboundUIState, setAppLayout, scrollContainerRef, uiEffects]);

  const cleanupUIEffects = useCallback(() => {
    setInboundUIStore({ isEditingQuantity: false });
    setInboundUIState({ isQuantityEditActive: false });
    setAppLayout({ withTabBar: defaultTabBarState });
    scrollContainerRef.current?.removeEventListener("wheel", preventScroll);
    scrollContainerRef.current?.removeEventListener("touchmove", preventScroll);
    uiEffects?.cleanup();
  }, [
    setInboundUIStore,
    setInboundUIState,
    setAppLayout,
    defaultTabBarState,
    scrollContainerRef,
    uiEffects,
  ]);

  const preFocusJob = useCallback(async () => {
    let resolver: (value: unknown) => void;
    const timeoutPromise = new Promise((resolve) => {
      resolver = resolve;
    });
    setTimeout(() => {
      executeUIEffects();
      resolver(undefined);
    });
    await timeoutPromise;
    allowFocus.current = true;
  }, [executeUIEffects]);

  // EFFECTS

  useEffect(() => {
    oldInputQuantityRef.current = value;
    // if the value update doesn't come from the component's input,
    // reflect it in the local state representation
    if (value.toString() !== displayValue) {
      setDisplayValue(value.toString());
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    return () => {
      if (isEditing) {
        cleanupUIEffects();
      }
    };
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useEffect(() => {
    if (!isEditing) {
      return;
    }
    async function asyncEffect() {
      await preFocusJob();
      inputRef.current?.focus();
    }
    asyncEffect();
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  // COMPOSING RETURN VALUE

  const onClick = useCallback(
    async (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      event?.preventDefault();
      if (isEditing) {
        return;
      }
      setIsEditing(true);
      // The focus event that comes along with the click event (it actually occurs before click),
      // is rejected by default because alowFocus is false.
      // This train of thought is being picked up by the effect that's listening to `isEditing`.
      // The effect runs UI effects, waits for them to finish, sets allowFocus to true and then
      // triggers focus() manually.
      // We need to do this to allow any UI effects to be finished BEFORE any automatic scrolling
      // or viewport resizing or offsetting from the browser. For the same reason, this needs to
      // be in an effect, so that the focus happens in the next render cycle.
    },
    [isEditing, setIsEditing],
  );

  const onFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      // focussing is disabled by default UNLESS it is triggered manually through the effect that
      // is triggered by the onClick handler.
      if (!allowFocus.current) {
        forceBlur.current = true;
        // need to set forceBlur because blurring is also disabled by default unless explicitly
        // coming from here (or the blur function exposed by this hook).
        event.target.blur();
        return;
      }
      allowFocus.current = false;
      scrollContainerRef.current?.scrollTo({
        top: cardRef.current?.offsetTop,
        behavior: "smooth",
      });
      event.preventDefault();
      event.currentTarget.select();
    },
    [cardRef, scrollContainerRef],
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newQuantity = event.target.value;
      if (fastTypeCounter.current === 0) {
        inputContentBeforeFastTyping.current = displayValue;
      }
      const wasChangeApplied = handleDisplayValueChange(newQuantity);
      if (!wasChangeApplied) {
        return;
      }
      fastTypeCounter.current += 1;
      resetFastTypeCounterWhenIdle();
    },
    [displayValue, handleDisplayValueChange, resetFastTypeCounterWhenIdle],
  );

  const onBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (forceBlur.current) {
        forceBlur.current = false;
        return;
      }
      if (!allowBlur && !blurringFromSubmitEvent.current) {
        allowFocus.current = true;
        event.target.focus({ preventScroll: true });
        return;
      }
      setIsEditing(false);
      setTimeout(cleanupUIEffects);
      if (blurringFromSubmitEvent.current) {
        blurringFromSubmitEvent.current = false;
        return;
      }
      submitQuantityChange();
    },
    [allowBlur, cleanupUIEffects, submitQuantityChange],
  );

  // Reset Input Value
  const resetInputValue = useCallback(() => {
    const initialValue = value.toString();
    setDisplayValue(initialValue);
    if (inputRef.current) {
      inputRef.current.value = initialValue;
    }
  }, [value, setDisplayValue]);

  const inputProps = useMemo(
    () => ({
      onClick,
      onFocus,
      onBlur,
      onChange,
      value: displayValue,
      onSelect: () => {},
      onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => event.stopPropagation(),
      elementRef: (element: HTMLInputElement) => {
        inputRef.current = element;
      },
    }),
    [onClick, onFocus, onBlur, onChange, displayValue],
  );

  return {
    fieldProps: {
      inputProps,
      onSubmitForm: (event: React.FormEvent<HTMLInputElement>) => {
        event.preventDefault();
        blurringFromSubmitEvent.current = true;
        inputRef.current?.blur();
        submitQuantityChange();
      },
    },
    focus: async (...args) => {
      setIsEditing(true);
      await preFocusJob();
      inputRef.current?.focus(...args);
    },
    blur: (...args) => {
      inputRef.current?.blur(...args);
    },
    resetInputValue,
  };
}
