import { memo, useEffect, useLayoutEffect, useRef, useState } from "react";

import { Flex, Box, BoxProps } from "@chakra-ui/react";

import { TitleM } from "ui/Typography/Typography";
import { isNotNullNorUndefined, isNullOrUndefined } from "utils/tsHelpers";

function Overlay({ ...props }: BoxProps) {
  return <Box position="absolute" top="0px" pointerEvents="none" zIndex={3} {...props} />;
}

type DateTimePickerColumnProps = {
  elements: number[];
  elementHeight: number;
  columnWidth: number;
  onChange: (newValue: number) => void;
  initialValue?: number;
  showLeadingZero?: boolean;
  size?: "s" | "m";
  dataTestId?: string;
  id?: string;
};

function DateTimePickerColumnComponent({
  elementHeight,
  columnWidth,
  elements,
  onChange,
  initialValue,
  showLeadingZero = false,
  size = "s",
  dataTestId,
  id,
}: DateTimePickerColumnProps) {
  const canTriggerHandleScroll = useRef(true);
  const valueRef = useRef(initialValue ?? 0);
  const scrollToRef = useRef(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const [elementsCopy, setElementsCopy] = useState<number[]>([]);

  useEffect(() => {
    canTriggerHandleScroll.current = false;
    const indexOfValueInElements = elements.indexOf(valueRef.current);
    let scrollToValue = indexOfValueInElements * elementHeight;
    if (indexOfValueInElements === -1) {
      const firstElementsValue = elements[0];
      const lastElementsValue = elements[elements.length - 1];
      const newValue =
        valueRef.current < firstElementsValue ? firstElementsValue : lastElementsValue;
      scrollToValue = elements.indexOf(newValue) * elementHeight;
      valueRef.current = newValue;
      if (isNotNullNorUndefined(onChange)) {
        onChange(newValue);
      }
    }
    scrollToRef.current = scrollToValue;
    setElementsCopy([...elements]);
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements]);

  useLayoutEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({ top: scrollToRef.current });
    }
    canTriggerHandleScroll.current = true;
  }, [elementsCopy]);

  useEffect(() => {
    const scrollRef = scrollContainerRef.current;
    const handleScroll = (event: Event) => {
      if (!scrollRef || !canTriggerHandleScroll.current) {
        return;
      }
      const newValue =
        elements[Math.round((event.target as HTMLDivElement).scrollTop / elementHeight)];
      if (isNullOrUndefined(newValue) || newValue === valueRef.current) {
        return;
      }
      valueRef.current = newValue;
      if (isNotNullNorUndefined(onChange)) {
        onChange(newValue);
      }
    };
    scrollRef?.addEventListener("scroll", handleScroll);
    return () => {
      scrollRef?.removeEventListener("scroll", handleScroll);
    };
  }, [elements, onChange, elementHeight]);

  return (
    <Box position="relative">
      <Overlay
        top={0}
        minHeight={`${elementHeight * (size === "s" ? 2 : 3)}px`}
        minWidth={`${columnWidth}px`}
        background="linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1))"
      />
      <Overlay
        top={(size === "s" ? 3 : 4) * elementHeight}
        minHeight={`${elementHeight * 3}px`}
        minWidth={`${columnWidth}px`}
        background="linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))"
      />
      <Flex
        minWidth={`${columnWidth}px`}
        overflowY="scroll"
        direction="column"
        height={elementHeight * (size === "s" ? 6 : 7)}
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        scrollSnapType="y mandatory"
        ref={scrollContainerRef}
        data-testid={dataTestId}
        id={id}
      >
        <Box minHeight={elementHeight} scrollSnapAlign="start" />
        <Box minHeight={elementHeight} scrollSnapAlign="start" />
        {size === "m" && <Box minHeight={elementHeight} scrollSnapAlign="start" />}
        {elementsCopy.map((item) => (
          <Flex
            key={item}
            position="relative"
            scrollSnapAlign="start"
            alignItems="center"
            justifyContent="center"
            minHeight={elementHeight}
            zIndex={2}
          >
            <TitleM>{showLeadingZero ? `0${item}`.slice(-2) : item}</TitleM>
          </Flex>
        ))}
        <Box minHeight={elementHeight * 3} scrollSnapAlign="none" />
      </Flex>
    </Box>
  );
}

export const DateTimePickerColumn = memo(DateTimePickerColumnComponent);

DateTimePickerColumn.displayName = "DateTimePickerColumn";
