import create from "zustand";
import { immer } from "zustand/middleware/immer";

import { MultipleDesadv } from "__graphql__/types";
import { SerializedListVerificationCheck } from "flows/Inbound/models/listVerificationCheck/types";
import { TranslatedProductSearchUnit } from "shared/models/productSearchUnit/types";
import { sortByShelf } from "utils/item";

import {
  ClaimedProductsState,
  DeliveryType,
  InboundStoreContext,
  InboundUIState,
  InboundUnitAddOrigin,
  TranslatedProduct,
} from "./types";
import {
  createInitialStockUpdate,
  extractInboundPlan,
  selectDespatchAdviceItemBySku,
  sumStockUpdatePlanTotal,
} from "./utils";

export const DEFAULT_LETTER_RANGE_ID = "AD";
export const DRAFT_LIST_PERSIST_ID = "draft-list-persist-id";

type InboundStoreActions = InboundStoreContext & {
  setSelectedOption(delivery: DeliveryType | null): void;
  setDeliverySSCC(deliverySSCC: string | null): void;
  setSharedListName(sharedListName: string | null): void;
  setSharedListId(sharedListId: string | null): void;
  addInboundUnitToPreDroppingList: (
    unit: TranslatedProductSearchUnit,
    isExisted: boolean,
    origin?: InboundUnitAddOrigin,
  ) => void;
  moveDuplicatedProductToTop: (sku: string) => void;
  updateDespatchAdviceItems: (data: MultipleDesadv[] | null) => void;
  updateInboundQuantityBySku: (sku: string, newQuantityForDisplayUnit: number) => void;
  updateStockForOutboundReasons: (sku: string, newQuantityDelta: number) => void;
  updateHandlingUnitSize: (sku: string, size: number) => void;
  confirmSkuQuantity: (sku: string) => void;
  removeInboundUnitFromPreDroppingList: (sku: string) => void;
  completeListVerificationCheck: (checkKey: string) => void;
  toggleDroppingListShelfSortOrder: () => void;
  updateStockForInboundReasons: (sku: string) => void;
  setListVerificationChecks: (verificationChecks: SerializedListVerificationCheck[]) => void;
  setInboundUnitIdToOutbound: (sku: string) => void;
  setInboundPreviewProducts: (inboundPreviewProducts: TranslatedProduct[]) => void;
  setInboundClaimedProducts: (data: ClaimedProductsState) => void;
  removeInboundUnitFromDroppingList: (sku: string) => void;
  updateInboundQuantityForDisplay: (sku: string, newQuantity: number) => void;
  setInboundUIState: (inboundUIState: Partial<InboundUIState>) => void;
  persistPreDroppingState: () => void;
  resetPreDroppingState: () => void;
  resetToInitialState: () => void;
};

// Initial state for the UI
const initialInboundUIState: InboundUIState = {
  isQuantityEditActive: false,
  currentLetterRangeId: DEFAULT_LETTER_RANGE_ID,
  isScanningFailedModalVisible: false,
  isPartialOutboundAnimationVisible: null,
  skuFocusedForQuantityInput: undefined,
  isHUSizeEditActive: false,
  isQuantityMismatchBannerVisible: false,
  isDespatchAdviceModalVisible: false, //
  isDespatchAdviceInfoBannerVisible: true,
  isProductAlreadyAddedBannerVisible: false,
  isUnverifiedQuantityModalVisible: false,
  isAddToSharedListModalVisible: false,
  isUnsavedChangesWarningModalVisible: false,
  isShareListWarningModalVisible: false,
  isListValidationActive: false,
  isUnclaimListConfirmationModalVisible: false,
  isUnclaimedListConfirmationModalVisible: false,
  isSharedListNameEditActive: false,
  isDeleteFinalItemModalVisibleForSku: null,
  isClaimedQuantityEditActiveForSku: null,
  isPreDroppingItemRemovalModalVisible: false,
  isRefetchClaimedListConfirmationModalVisible: false,
};

// Initial state for the inbound store
const initialInboundStoreState: InboundStoreContext = {
  deliverySSCC: null,
  deliveryType: null,
  // pre-dropping
  sharedListName: null,
  sharedListId: null,
  inboundUnitsSortedByDate: [],
  inboundUnitsMap: {},
  inboundUnitsStockUpdates: {},
  inboundUnitsDisplayStates: {},
  outboundSku: undefined,
  shelfStockLevels: {},
  verificationChecks: [],
  despatchAdviceItems: {},
  skusWithUnconfirmedQuantities: [],
  inboundPreviewProducts: [],
  // dropping
  droppingListShelfSortOrder: "asc",
  droppingListId: null,
  droppingListName: null,
  claimedProductsMap: {},
  claimedProductsSortedByShelf: [],
  claimedProductsStockUpdates: {},
  inboundUIState: initialInboundUIState,
};

export const useInboundStore = create<InboundStoreActions>()(
  immer((set, get) => ({
    ...initialInboundStoreState,
    // Set delivery type option
    setSelectedOption: (delivery) => set({ deliveryType: delivery }),
    setDeliverySSCC: (deliverySSCC) => set({ deliverySSCC }),
    setSharedListName: (sharedListName) => set({ sharedListName }),
    setSharedListId: (sharedListId) => set({ sharedListId }),
    /* Pre-Dropping */
    // Add inbound units with optimized handling
    addInboundUnitToPreDroppingList: (inboundUnit, isExisted, origin) => {
      set((state) => {
        const sku = inboundUnit.productSku;
        const inboundingMethod = origin;

        if (state.inboundUnitsMap[sku]) {
          return;
        }

        const { sharedListId } = state;

        if (!sharedListId) return;

        const selectedDespatchAdviceItem = selectDespatchAdviceItemBySku(
          sku,
          state.despatchAdviceItems,
        );
        const newQuantity =
          !isExisted && selectedDespatchAdviceItem
            ? selectedDespatchAdviceItem.totalQuantity
            : inboundUnit.quantity;
        const unitSizeForDisplay =
          !isExisted && selectedDespatchAdviceItem
            ? selectedDespatchAdviceItem.handlingUnitSize
            : inboundUnit.handlingUnitSize || inboundUnit.quantity;

        if (isExisted) {
          const persistedData = localStorage.getItem(DRAFT_LIST_PERSIST_ID);
          const parsedData = persistedData ? JSON.parse(persistedData) : {};
          const currentPersistedData = parsedData[sharedListId];
          state.skusWithUnconfirmedQuantities =
            currentPersistedData?.skusWithUnconfirmedQuantities || [];
          state.verificationChecks = currentPersistedData?.verificationChecks || [];
        }
        // Update state based on the selected despatch advice item
        if (selectedDespatchAdviceItem && !isExisted) {
          state.skusWithUnconfirmedQuantities = Array.from(
            new Set([...state.skusWithUnconfirmedQuantities, sku]),
          );
        }
        // Update the inbound units sorted by date
        state.inboundUnitsSortedByDate = isExisted
          ? [...state.inboundUnitsSortedByDate, sku]
          : [sku, ...state.inboundUnitsSortedByDate].sort(
              (a, b) =>
                state.skusWithUnconfirmedQuantities.indexOf(b) -
                state.skusWithUnconfirmedQuantities.indexOf(a),
            );

        // Update inbound units mapping and stock updates
        state.inboundUnitsMap[sku] = { ...inboundUnit, inboundingMethod };
        state.inboundUnitsStockUpdates[sku] = createInitialStockUpdate(newQuantity);
        state.inboundUnitsDisplayStates[sku] = { unitSizeForDisplay };
        state.inboundUIState.skuFocusedForQuantityInput =
          !isExisted && newQuantity === 1 ? sku : null;
        state.inboundUIState.isListValidationActive = true;
      });
    },
    // Move a duplicated product to the top of the inbound units list
    moveDuplicatedProductToTop: (sku) => {
      set((state) => {
        state.inboundUnitsSortedByDate = [
          sku,
          ...state.inboundUnitsSortedByDate.filter((existingSku) => existingSku !== sku),
        ];
        state.inboundUIState.isProductAlreadyAddedBannerVisible = true;
      });
    },
    // Updates despatch advice items
    updateDespatchAdviceItems: (data) =>
      set((state) => {
        // Reset despatchAdviceItems to ensure a fresh start
        state.despatchAdviceItems = {};
        if (Array.isArray(data)) {
          data.forEach((desadv) => {
            if (desadv?.id && Array.isArray(desadv.items)) {
              const existingItems = state.despatchAdviceItems[desadv.id] ?? [];
              state.despatchAdviceItems[desadv.id] = [...existingItems, ...desadv.items];
            }
          });
        }
      }),
    // Updates inbound quantity by SKU
    updateInboundQuantityBySku: (sku, newQuantityForDisplayUnit) => {
      set((state) => {
        const stockUpdateState = state.inboundUnitsStockUpdates[sku];
        if (
          !stockUpdateState ||
          stockUpdateState.stockUpdated === true ||
          newQuantityForDisplayUnit < 1
        )
          return;

        const displayState = state.inboundUnitsDisplayStates[sku];
        if (!displayState) return;

        const currentInboundPlan = extractInboundPlan(stockUpdateState.stockUpdatePlan);
        if (!currentInboundPlan) return;

        const oldTotalQuantity = sumStockUpdatePlanTotal(stockUpdateState.stockUpdatePlan);
        const newTotalQuantity = newQuantityForDisplayUnit * displayState.unitSizeForDisplay;
        const quantityAdjustment = newTotalQuantity - oldTotalQuantity;
        currentInboundPlan.quantityDelta += quantityAdjustment;
        state.skusWithUnconfirmedQuantities = state.skusWithUnconfirmedQuantities.filter(
          (unconfirmedSku) => unconfirmedSku !== sku,
        );
        state.inboundUIState.isListValidationActive = true;
      });
    },
    // Update stock for outbound reasons
    updateStockForOutboundReasons: (sku: string, newQuantityDelta: number) =>
      set((state) => {
        const stockUpdateState = state.inboundUnitsStockUpdates[sku];
        if (!stockUpdateState) return;

        if (stockUpdateState.stockUpdated === true) return;

        const currentInboundPlan = extractInboundPlan(stockUpdateState.stockUpdatePlan);
        if (!currentInboundPlan) return;

        const displayState = state.inboundUnitsDisplayStates[sku];
        if (!displayState) return;

        currentInboundPlan.quantityDelta = newQuantityDelta;

        state.inboundUIState.isListValidationActive = true;

        displayState.unitSizeForDisplay = 1;
      }),
    // Updates the handling unit size for a given SKU
    updateHandlingUnitSize: (sku, size) => {
      set((state) => {
        const displayState = state.inboundUnitsDisplayStates[sku];
        const inboundStockUpdate = extractInboundPlan(
          state.inboundUnitsStockUpdates[sku].stockUpdatePlan,
        );

        if (!inboundStockUpdate) return;

        const currentQuantityDelta = inboundStockUpdate.quantityDelta;
        inboundStockUpdate.quantityDelta =
          (currentQuantityDelta / displayState.unitSizeForDisplay) * size;
        displayState.unitSizeForDisplay = size;
        state.inboundUIState.isListValidationActive = true;
      });
    },
    // Confirm the SKU quantity
    confirmSkuQuantity: (sku) => {
      set((state) => {
        state.skusWithUnconfirmedQuantities = state.skusWithUnconfirmedQuantities.filter(
          (unconfirmedSku) => unconfirmedSku !== sku,
        );
        state.inboundUIState.isDespatchAdviceInfoBannerVisible = false;
      });
    },
    // Remove inbound unit from the pre-dropping list
    removeInboundUnitFromPreDroppingList: (sku) => {
      set((state) => {
        const stockUpdateState = state.inboundUnitsStockUpdates[sku];
        if (!stockUpdateState || stockUpdateState.stockUpdated === true) return;
        state.inboundUnitsSortedByDate = state.inboundUnitsSortedByDate.filter(
          (item) => item !== sku,
        );
        state.skusWithUnconfirmedQuantities = state.skusWithUnconfirmedQuantities.filter(
          (unconfirmedSku) => unconfirmedSku !== sku,
        );
        delete state.inboundUnitsMap[sku];
        delete state.inboundUnitsStockUpdates[sku];
        delete state.inboundUnitsDisplayStates[sku];
        state.inboundUIState.isDespatchAdviceInfoBannerVisible = false;
        state.inboundUIState.isProductAlreadyAddedBannerVisible = false;
        state.inboundUIState.isQuantityMismatchBannerVisible = false;
      });
    },
    // Complete the verification check
    completeListVerificationCheck: (checkKey) =>
      set((state) => {
        const existingCheck = state.verificationChecks.find(({ key }) => key === checkKey);
        if (!existingCheck) return;
        const stockUpdateState = state.inboundUnitsStockUpdates[existingCheck.sku];
        if (stockUpdateState && existingCheck.data && "quantity" in existingCheck.data) {
          const newQuantity = extractInboundPlan(stockUpdateState.stockUpdatePlan)?.quantityDelta;
          existingCheck.data.quantity = newQuantity ?? existingCheck.data.quantity;
        }
        existingCheck.isCompleted = true;
      }),
    // Setters for ListVerification Checks
    setListVerificationChecks: (verificationChecks) =>
      set((state) => {
        state.verificationChecks = verificationChecks;
      }),
    // Setters for Outbound state
    setInboundUnitIdToOutbound: (sku) =>
      set((state) => {
        state.outboundSku = sku;
      }),
    // Setter for inbound preview products
    setInboundPreviewProducts: (inboundPreviewProducts) =>
      set((state) => {
        state.inboundPreviewProducts = inboundPreviewProducts;
      }),
    /* Dropping */
    // toggle the dropping list shelf sort order
    toggleDroppingListShelfSortOrder: () =>
      set((state) => {
        const newSortOrder = state.droppingListShelfSortOrder === "asc" ? "desc" : "asc";
        const sortedProducts = state.claimedProductsSortedByShelf.sort((a, b) =>
          sortByShelf(a, b, newSortOrder),
        );
        state.droppingListShelfSortOrder = newSortOrder;
        state.claimedProductsSortedByShelf = sortedProducts;
      }),
    // Update stock for outbound reasons
    updateStockForInboundReasons: (sku) => {
      set((state) => {
        const stockUpdateState = state.claimedProductsStockUpdates[sku];
        if (!stockUpdateState || stockUpdateState.stockUpdated) return;
        stockUpdateState.stockUpdated = true;
      });
    },
    // Setters for InboundClaimedProducts
    setInboundClaimedProducts: (claimedProductsData) => {
      set((state) => {
        state.droppingListId = claimedProductsData.droppingListId;
        state.droppingListName = claimedProductsData.droppingListName;
        state.claimedProductsMap = claimedProductsData.claimedProductsMap;
        state.claimedProductsStockUpdates = claimedProductsData.claimedProductsStockUpdates;
        const sortedProducts = claimedProductsData.claimedProductsSortedByShelf.sort((a, b) =>
          sortByShelf(a, b, state.droppingListShelfSortOrder),
        );
        state.claimedProductsSortedByShelf = sortedProducts;
      });
    },
    // Remove inbound unit from the dropping list
    removeInboundUnitFromDroppingList: (sku) => {
      set((state) => {
        const stockUpdateState = state.claimedProductsStockUpdates[sku];
        if (!stockUpdateState || stockUpdateState.stockUpdated === true) return;
        state.claimedProductsSortedByShelf = state.claimedProductsSortedByShelf.filter(
          (item) => item.sku !== sku,
        );
        delete state.claimedProductsMap[sku];
        delete state.claimedProductsStockUpdates[sku];
      });
    },
    // Update the quantity of product
    updateInboundQuantityForDisplay: (sku, newQuantity) => {
      set((state) => {
        const stockUpdateState = state.claimedProductsStockUpdates[sku];
        if (!stockUpdateState || stockUpdateState.stockUpdated === true || newQuantity < 1) return;
        const displayState = state.claimedProductsMap[sku];
        if (!displayState) return;
        const currentInboundPlan = extractInboundPlan(stockUpdateState.stockUpdatePlan);
        if (!currentInboundPlan) return;
        displayState.totalInboundQuantity = newQuantity;
        displayState.unitSizeForDisplay = newQuantity;
        displayState.handlingUnitSize = 1;
        currentInboundPlan.quantityDelta = newQuantity;
      });
    },
    /* UI State */
    // Setters for UI state
    setInboundUIState: (inboundUIState) =>
      set((state) => {
        state.inboundUIState = { ...state.inboundUIState, ...inboundUIState };
      }),
    persistPreDroppingState: () => {
      const { sharedListId, skusWithUnconfirmedQuantities, verificationChecks } = get();
      if (sharedListId) {
        const persistData = {
          [sharedListId]: {
            skusWithUnconfirmedQuantities,
            verificationChecks,
          },
        };
        localStorage.setItem(DRAFT_LIST_PERSIST_ID, JSON.stringify(persistData));
      }
      set(() => initialInboundStoreState);
    },
    resetPreDroppingState: () => {
      localStorage.removeItem(DRAFT_LIST_PERSIST_ID);
      set(() => initialInboundStoreState);
    },
    resetToInitialState: () => set(() => initialInboundStoreState),
  })),
);
