import { useCallback, useMemo, useState } from "react";

import { Box, Flex, IconButton } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { routes } from "config/routes";
import { EppoFeatureFlags } from "core/types/flags";
import { EndDroppingProcessButton } from "flows/Inbound/components/EndDroppingProcessButton";
import { RestockingListItem } from "flows/Inventory/flows/RestockingList/components/RestockingListItem";
import { RestockingItemStatus } from "flows/Inventory/flows/RestockingList/models/restockingItem/types";
import { Page } from "shared/components/Page";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useListScrollEffects } from "shared/hooks/useListScrollEffects";
import { AscDscIcon } from "ui/AscDscIcon/AscDscIcon";
import { Banner } from "ui/Banner/Banner";
import { TapIcon } from "ui/Icons/Icons";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { transformChunks } from "utils/ui";

import { usePrivateRestockingList } from "../../services/restockingService/service";

export function RestockingDropOff() {
  const {
    sortedTranslatedRestockingItems,
    restockingItemsSortOrder,
    itemInRestockingProcess,
    toggleRestockingItemsSortOrder,
    restockItem,
    deletePrivateList,
  } = usePrivateRestockingList();

  const navigate = useNavigate();
  const intl = useIntl();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isBannerVisible, setBannerVisible] = useState(true);

  const activeRestockingItem = useMemo(
    () => sortedTranslatedRestockingItems.find((item) => item.status !== RestockingItemStatus.done),
    [sortedTranslatedRestockingItems],
  );

  const { itemsRef, scrollContainerRef } = useListScrollEffects({
    activeItemId: activeRestockingItem?.sku ?? null,
    listItemIds: sortedTranslatedRestockingItems.map((item) => item.sku),
    scrollOffset: 70,
  });

  const shouldShowFinishRestockingButton = sortedTranslatedRestockingItems.every(
    (item) => item.status === RestockingItemStatus.done,
  );

  const deletePrivateListAndRedirect = useCallback(async () => {
    setIsDeleting(true);
    await deletePrivateList();
    navigate(routes.inventory.restockingList.root);
  }, [deletePrivateList, navigate]);

  const handleRestock = useCallback(
    (sku: string) => {
      if (!isDeleting) {
        restockItem(sku);
        setBannerVisible(false);
      }
    },
    [isDeleting, restockItem, setBannerVisible],
  );
  const { isFeatureEnabled: isProductFacingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.PRODUCT_FACING,
  );
  const showTapOnCardAlert =
    isProductFacingEnabled && isBannerVisible && !shouldShowFinishRestockingButton;

  return (
    <Page isFull isBgGrey height="100%">
      <NavigationHeader
        title={intl.formatMessage({ id: "flows.inventory.restocking-list.pages.drop-off.title" })}
        onClickGoBack={() => navigate(routes.inventory.restockingList.root)}
        rightElement={
          <IconButton
            size="xs"
            variant="ghost"
            aria-label="Change sort order"
            icon={<AscDscIcon height="24px" width="24px" sortState={restockingItemsSortOrder} />}
            onClick={toggleRestockingItemsSortOrder}
          />
        }
      />
      {showTapOnCardAlert && (
        <Banner
          colorVariant="purple"
          icon={<TapIcon />}
          subTitle={
            <FormattedMessage
              id="flows.inventory.restocking-list.pages.drop-off.banner"
              values={transformChunks({ b: { as: "strong", color: "black" } })}
            />
          }
          onClose={() => setBannerVisible(false)}
        />
      )}
      <Box overflowY="scroll" w="100%" h="100%" ref={scrollContainerRef}>
        <Flex
          flex={1}
          flexDirection="column"
          w="100%"
          overflowY="scroll"
          alignItems="center"
          justifyContent="flex-start"
          gap="s200"
          pt="s200"
          pb="72px"
        >
          {sortedTranslatedRestockingItems.map((restockingItem, index) => (
            <RestockingListItem
              key={restockingItem.sku}
              restockingItem={restockingItem}
              isClickable
              isLoading={itemInRestockingProcess === restockingItem.sku}
              elementRef={itemsRef.current[index]}
              onClick={() => handleRestock(restockingItem.sku)}
              shouldShowProductFacing={!!isProductFacingEnabled}
            />
          ))}
        </Flex>

        <EndDroppingProcessButton
          shouldShowButton={shouldShowFinishRestockingButton}
          onClickButton={deletePrivateListAndRedirect}
          labelMessageId="flows.inventory.restocking-list.pages.list-preparation.finish-restocking"
          isLoading={isDeleting}
          bottom={0}
          buttonTestId="finish-restocking-button"
        />
      </Box>
    </Page>
  );
}
