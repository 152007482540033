import { useCallback, useEffect, useRef, useState } from "react";

import { debounce } from "utils/debounce";

export type ScanOptions = {
  onScan?: (scan: string) => void;
};

export function useScan(options?: ScanOptions) {
  const value = useRef<string>("");
  const [scannedValue, setScannedValue] = useState("");

  // When a scan occured the keydown is trigger very fast.
  // We are using the debounce function to reveal that behavior and know when the keydown sequence comes from a Zebra Scan
  // eslint-disable-next-line react-compiler/react-compiler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeydown = useCallback(
    debounce(() => {
      // if the function has been called inside and the value stored in ref is greater than 3 we know that is a scan
      const isScan = value.current.length > 3;
      if (isScan) {
        // store the scanned value in the state
        setScannedValue(value.current);
        if (options?.onScan) {
          // call the callback provided in options
          options.onScan(value.current);
        }
      }
      // reset the value stored in ref
      value.current = "";
    }, 10),
    [scannedValue, options?.onScan],
  );

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key.length === 1) {
        // concat the letter returned by the event in a ref value
        value.current += event.key;
        handleKeydown();
      }
    };
    // This event will be trigger when a QR code is scanned by the ZEBRA. e.g. 'FOO' will trigger this event 3 times
    window.addEventListener("keydown", onKeyDown);
    // cleanup the event
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [handleKeydown]);

  return {
    scannedValue,
  };
}
