import { useState, useCallback } from "react";

import { isApolloError } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import {
  CollaborativeInboundProgressedAction,
  CollaborativeInboundUpdateState,
  PageName,
} from "analytics/events";
import { routes } from "config/routes";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { EppoFeatureFlags } from "core/types/flags";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { formatEmployeeName } from "utils/formats";
import { isNullOrUndefined } from "utils/tsHelpers";

import {
  INBOUND_UNIT_ADD_ORIGIN_MAP,
  useInboundEventTracker,
} from "../hooks/useInboundEventTracker/useInboundEventTracker";
import {
  useAddProductsToInboundingListMutation,
  useCreateInboundingListMutation,
  useShareInboundingListMutation,
} from "../queries/collaborativeInbound/collaborativeInbound.generated";
import { useGetMultipleDesadvsRolliIdLazyQuery } from "../queries/despatchAdvice/despatchAdvice.generated";
import { DeliveryType, InboundStoreContext, InboundUnitAddOrigin } from "./inboundStore/types";
import { useInboundStore } from "./inboundStore/useInboundStore";
import {
  getMatchedtDesadvId,
  mapDeliveryTypeToInboundingType,
  selectDespatchAdviceItemBySku,
  sumStockUpdatePlanTotal,
} from "./inboundStore/utils";

const CREATE_SHARED_LIST_ERROR_TOAST_ID = "create_shared_list_error_toast";
const CREATE_SHARED_LIST_SUCCESS_TOAST_ID = "create_shared_list_success_toast";
const ADD_ITEMS_SHARED_LIST_ERROR_TOAST_ID = "add_items_shared_list_error_toast";
const ADD_ITEMS_SHARED_LIST_SUCCESS_TOAST_ID = "add_items_shared_list_success_toast";
const SHARE_LIST_SUCCESS_TOAST_ID = "share_list_success_toast";
const SHARE_LIST_ERROR_TOAST_ID = "share_list_error_toast";

const formatSharedListName = (firstName: string, lastName: string) => {
  const formattedName = formatEmployeeName(firstName, lastName);
  const currentTimeInGerman = new Date().toLocaleTimeString("de-DE").slice(0, 5);
  return `${formattedName}'s list - ${currentTimeInGerman}`;
};

const processInboundUnitStockUpdate = ({
  productSku,
  inboundUnitsStockUpdates,
  inboundUnitsDisplayStates,
  inboundUnitsMap,
  deliverySSCC,
  despatchAdviceItems,
}: Pick<
  InboundStoreContext,
  | "inboundUnitsStockUpdates"
  | "inboundUnitsDisplayStates"
  | "inboundUnitsMap"
  | "deliverySSCC"
  | "despatchAdviceItems"
> & { productSku: string }) => {
  const getStockUpdatePlanTotal = (sku: string) =>
    sumStockUpdatePlanTotal(inboundUnitsStockUpdates[sku]?.stockUpdatePlan);

  const getDespatchAdviceItemDetails = (sku: string) => {
    const despatchAdviceItem = selectDespatchAdviceItemBySku(sku, despatchAdviceItems);
    return {
      desadvId: getMatchedtDesadvId(sku, despatchAdviceItems),
      totalQuantity: despatchAdviceItem?.totalQuantity?.toString(),
      unitType: despatchAdviceItem?.unitType,
    };
  };

  const getMetadata = (sku: string, despatchAdviceItemDetails: any, scannedEan: string | null) => ({
    desadv: despatchAdviceItemDetails.desadvId,
    expectedQuantity: despatchAdviceItemDetails.totalQuantity,
    inboundingMethod: inboundUnitsMap[sku]?.inboundingMethod,
    inboundingUnit: despatchAdviceItemDetails.unitType,
    scannedEan,
    sscc: deliverySSCC,
  });

  const handlingUnitSize = inboundUnitsDisplayStates[productSku]?.unitSizeForDisplay || 1;
  const stockUpdatePlanTotal = getStockUpdatePlanTotal(productSku);
  const despatchAdviceItemDetails = getDespatchAdviceItemDetails(productSku);
  const { ean = "", inboundingMethod } = inboundUnitsMap[productSku] || {};
  const scannedEan = inboundingMethod === "scan" ? ean : null;
  const metadata = getMetadata(productSku, despatchAdviceItemDetails, scannedEan);

  return {
    sku: productSku,
    quantity: stockUpdatePlanTotal,
    handlingUnitSize,
    metadata,
  };
};

const getInboundUnitsStockUpdates = ({
  inboundUnitsSortedByDate,
  inboundUnitsStockUpdates,
  inboundUnitsDisplayStates,
  inboundUnitsMap,
  deliverySSCC,
  despatchAdviceItems,
}: Pick<
  InboundStoreContext,
  | "inboundUnitsStockUpdates"
  | "inboundUnitsSortedByDate"
  | "inboundUnitsDisplayStates"
  | "inboundUnitsMap"
  | "deliverySSCC"
  | "despatchAdviceItems"
>) => {
  return inboundUnitsSortedByDate.map((sku) =>
    processInboundUnitStockUpdate({
      productSku: sku,
      inboundUnitsStockUpdates,
      inboundUnitsDisplayStates,
      inboundUnitsMap,
      deliverySSCC,
      despatchAdviceItems,
    }),
  );
};

export const useSharedListCreator = () => {
  const { isFeatureEnabled: isCollaborativeInboundV2Enabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.COLLABORATIVE_INBOUND_V2,
  );
  const navigate = useNavigate();
  const { showToastUI } = useCustomToast();
  const { sendInboundProgressed, sendInboundStateUpdate } = useInboundEventTracker();

  const { sendSegmentTrackEvent } = useAnalytics();
  const [isCreatingList, setIsCreatingList] = useState(false);
  const [isAddingItems, setIsAddingItems] = useState(false);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [isSharingList, setIsSharingList] = useState(false);

  const {
    deliveryType,
    sharedListId,
    setDeliverySSCC,
    setSharedListName,
    setSharedListId,
    updateDespatchAdviceItems,
    setInboundUIState,
    resetPreDroppingState,
    resetToInitialState,
  } = useInboundStore(
    (state) => ({
      deliveryType: state.deliveryType,
      sharedListId: state.sharedListId,
      setDeliverySSCC: state.setDeliverySSCC,
      setSharedListName: state.setSharedListName,
      setSharedListId: state.setSharedListId,
      updateDespatchAdviceItems: state.updateDespatchAdviceItems,
      setInboundUIState: state.setInboundUIState,
      resetPreDroppingState: state.resetPreDroppingState,
      resetToInitialState: state.resetToInitialState,
    }),
    shallow,
  );

  const { firstName, lastName } = useEmployeeStore(
    (state) => ({
      firstName: state.firstName,
      lastName: state.lastName,
    }),
    shallow,
  );

  const listName = formatSharedListName(firstName, lastName);

  const [fetchDesadvs] = useGetMultipleDesadvsRolliIdLazyQuery({
    onError: (e) => {
      if (
        isApolloError(e) &&
        e.graphQLErrors?.[0]?.extensions?.code === "DESPATCH_ADVICE_NOT_FOUND"
      ) {
        setInboundUIState({ isDespatchAdviceModalVisible: true });
      } else {
        const errorMessage = e instanceof Error ? e.message : "Unknown error";
        datadogRum.addError("Create Shared List Error", { errorMessage });
      }
    },
  });

  const [createSharedListMutation] = useCreateInboundingListMutation({
    onCompleted: (data) => {
      const listId = data?.createInboundingList?.id || null;
      setSharedListName(listName);
      setSharedListId(listId);
      navigate(routes.inbound.preDropping);
    },
  });

  const [addItemsToPreDroppingListMutation] = useAddProductsToInboundingListMutation({
    onCompleted: () => {
      if (isCollaborativeInboundV2Enabled) return;
      resetToInitialState();
      showToastUI({
        status: "success",
        id: ADD_ITEMS_SHARED_LIST_SUCCESS_TOAST_ID,
        title: "add_items_shared_list_success_toast",
      });
      navigate(routes.inbound.root);
    },
  });

  const [shareInboundingListMutation] = useShareInboundingListMutation();

  const createSharedList = useCallback(
    async (inboundingType: DeliveryType | null, sscc?: string) => {
      setIsCreatingList(true);
      setDeliverySSCC(sscc || null);
      try {
        if (!isNullOrUndefined(sscc)) {
          const { data } = await fetchDesadvs({ variables: { input: { rolliID: sscc } } });
          // If the fetch fails or there's no data, return early, Do not proceed with creating the shared list
          if (!data) return;
          updateDespatchAdviceItems(data.getMultipleDesadvsRolliID?.despatchAdvices ?? null);
          showToastUI({
            status: "success",
            id: CREATE_SHARED_LIST_SUCCESS_TOAST_ID,
            title: "create_shared_list_success_toast",
          });
        }
        const mappedInboundingType = mapDeliveryTypeToInboundingType(inboundingType);
        const { data: sharedListData } = await createSharedListMutation({
          variables: { input: { name: listName, sscc, inboundingType: mappedInboundingType } },
        });
        sendInboundStateUpdate({
          state: CollaborativeInboundUpdateState.ListPreparationStarted,
          droppingListId: sharedListData?.createInboundingList?.id,
          sscc,
          inboundingType,
        });
      } catch (error) {
        showToastUI({
          id: CREATE_SHARED_LIST_ERROR_TOAST_ID,
          title: "create-shared-list-error-toast",
        });
        const errorMessage = error instanceof Error ? error.message : "Unknown error";
        datadogRum.addError("Create Shared List Error", { errorMessage });
      } finally {
        setIsCreatingList(false);
      }
    },
    [
      setDeliverySSCC,
      createSharedListMutation,
      listName,
      sendInboundStateUpdate,
      fetchDesadvs,
      updateDespatchAdviceItems,
      showToastUI,
    ],
  );

  const addItemsToSharedList = useCallback(async () => {
    setIsAddingItems(true);
    setInboundUIState({ isAddToSharedListModalVisible: false });
    try {
      if (!sharedListId) return;
      const {
        inboundUnitsSortedByDate,
        inboundUnitsStockUpdates,
        inboundUnitsDisplayStates,
        inboundUnitsMap,
        deliverySSCC,
        despatchAdviceItems,
      } = useInboundStore.getState();
      const products = getInboundUnitsStockUpdates({
        inboundUnitsSortedByDate,
        inboundUnitsStockUpdates,
        inboundUnitsDisplayStates,
        inboundUnitsMap,
        deliverySSCC,
        despatchAdviceItems,
      }).filter((item) => item.quantity !== 0);
      await addItemsToPreDroppingListMutation({
        variables: {
          input: {
            listId: sharedListId,
            products,
          },
        },
      });
      sendInboundStateUpdate({
        state: CollaborativeInboundUpdateState.ListPreparationFinished,
        droppingListId: sharedListId,
        sscc: deliverySSCC,
        inboundingType: deliveryType,
      });
    } catch (error) {
      showToastUI({
        status: "error",
        id: ADD_ITEMS_SHARED_LIST_ERROR_TOAST_ID,
        title: "add_items_shared_list_error_toast",
      });
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("Add Items Shared List Error", { errorMessage });
    } finally {
      setIsAddingItems(false);
    }
  }, [
    addItemsToPreDroppingListMutation,
    deliveryType,
    sendInboundStateUpdate,
    setInboundUIState,
    sharedListId,
    showToastUI,
  ]);

  const addItemToSharedList = useCallback(
    async (productSku: string) => {
      setIsAddingItem(true);
      setInboundUIState({ isAddToSharedListModalVisible: false });
      try {
        if (!sharedListId) return;
        const {
          inboundUnitsStockUpdates,
          inboundUnitsDisplayStates,
          inboundUnitsMap,
          deliverySSCC,
          despatchAdviceItems,
        } = useInboundStore.getState();
        const product = processInboundUnitStockUpdate({
          productSku,
          inboundUnitsStockUpdates,
          inboundUnitsDisplayStates,
          inboundUnitsMap,
          deliverySSCC,
          despatchAdviceItems,
        });
        if (product.quantity === 0) return;
        const { data } = await addItemsToPreDroppingListMutation({
          variables: {
            input: {
              listId: sharedListId,
              products: [product],
            },
          },
        });
        if (data?.addProductsToInboundingList.success) {
          const origin = inboundUnitsMap[productSku].inboundingMethod as InboundUnitAddOrigin;
          sendInboundProgressed({
            sku: productSku,
            action: CollaborativeInboundProgressedAction.ProductAddedToList,
            method: INBOUND_UNIT_ADD_ORIGIN_MAP[origin],
          });
        }
      } catch (error) {
        showToastUI({
          status: "error",
          id: ADD_ITEMS_SHARED_LIST_ERROR_TOAST_ID,
          title: "add_items_shared_list_error_toast",
        });
        const errorMessage = error instanceof Error ? error.message : "Unknown error";
        datadogRum.addError("Add Item Shared List Error", { errorMessage });
      } finally {
        setIsAddingItem(false);
      }
    },
    [
      addItemsToPreDroppingListMutation,
      sendInboundProgressed,
      setInboundUIState,
      sharedListId,
      showToastUI,
    ],
  );

  const shareInboundingList = useCallback(async () => {
    setIsSharingList(true);
    try {
      if (!sharedListId) return;
      const { data } = await shareInboundingListMutation({
        variables: {
          input: {
            listId: sharedListId,
          },
        },
      });
      if (data?.shareInboundingList.success) {
        const { deliverySSCC } = useInboundStore.getState();
        showToastUI({
          status: "success",
          id: SHARE_LIST_SUCCESS_TOAST_ID,
          title: "share_list_success_toast",
        });
        sendInboundStateUpdate({
          state: CollaborativeInboundUpdateState.ListPreparationFinished,
          droppingListId: sharedListId,
          sscc: deliverySSCC,
          inboundingType: deliveryType,
        });
        resetPreDroppingState();
        navigate(routes.inbound.root);
      }
    } catch (error) {
      showToastUI({
        status: "error",
        id: SHARE_LIST_ERROR_TOAST_ID,
        title: "share_list_success_toast",
      });
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("Share List Error", { errorMessage });
    } finally {
      setIsSharingList(false);
    }
  }, [
    deliveryType,
    navigate,
    resetPreDroppingState,
    sendInboundStateUpdate,
    shareInboundingListMutation,
    sharedListId,
    showToastUI,
  ]);

  const handleDespatchAdviceModalContinue = useCallback(async () => {
    setInboundUIState({ isDespatchAdviceModalVisible: false });
    await createSharedList(deliveryType);
  }, [createSharedList, deliveryType, setInboundUIState]);

  const handleDespatchAdviceModalRetry = useCallback(() => {
    setInboundUIState({ isDespatchAdviceModalVisible: false });
    setDeliverySSCC(null);
  }, [setDeliverySSCC, setInboundUIState]);

  const handleAddItemsModalSave = useCallback(async () => {
    sendSegmentTrackEvent("inAppMessageShown", {
      screen_name: PageName.INBOUND_PAGE,
      component_content: sharedListId!,
      component_name: "save_list_confirmation",
    });
    setInboundUIState({ isAddToSharedListModalVisible: false });
    await addItemsToSharedList();
  }, [addItemsToSharedList, sendSegmentTrackEvent, setInboundUIState, sharedListId]);

  const handleAddItemsModalCancel = useCallback(() => {
    setInboundUIState({ isAddToSharedListModalVisible: false });
  }, [setInboundUIState]);

  const handleSharingList = useCallback(async () => {
    await shareInboundingList();
  }, [shareInboundingList]);

  return {
    isCreatingList,
    isAddingItems,
    isAddingItem,
    isSharingList,
    createSharedList,
    addItemsToSharedList,
    addItemToSharedList,
    handleDespatchAdviceModalContinue,
    handleDespatchAdviceModalRetry,
    handleAddItemsModalSave,
    handleAddItemsModalCancel,
    handleSharingList,
  };
};
