import { useState } from "react";

import { Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button } from "ui/Button/Button";
import { DATE_PICKER_WIDTH_NUMBER, DatePicker } from "ui/DatePicker/DatePicker";

export type DatePickerModalProps = {
  onClickConfirm: (newDate: Date) => void;
  onClickCancel: () => void;
  isOpen: boolean;
  initialValue?: Date;
  minDate?: Date;
  maxDate?: Date;
};

export function DatePickerModal({
  onClickConfirm,
  onClickCancel,
  isOpen,
  initialValue,
  minDate,
  maxDate,
}: DatePickerModalProps) {
  const [selectedDate, setSelectedDate] = useState<Date>(initialValue ?? new Date());

  const intl = useIntl();

  return (
    <Modal
      trapFocus={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={() => {}}
    >
      <ModalOverlay bg="rgba(0,0,0,0.2)" />
      <ModalContent maxW={DATE_PICKER_WIDTH_NUMBER + 32}>
        <ModalBody p="s200">
          <DatePicker
            dayLabel={intl.formatMessage({ id: "components.date-picker-modal.day" })}
            monthLabel={intl.formatMessage({ id: "components.date-picker-modal.month" })}
            yearLabel={intl.formatMessage({ id: "components.date-picker-modal.year" })}
            onChange={setSelectedDate}
            initialValue={selectedDate}
            minDate={minDate}
            maxDate={maxDate}
          />
        </ModalBody>
        <ModalFooter paddingX="s200" paddingTop="0" flexDir="column">
          <Button
            onClick={() => onClickConfirm(selectedDate)}
            flinkVariant="primary"
            size="lg"
            width="full"
            data-testid="date-picker-modal-confirm-button"
          >
            <FormattedMessage id="components.date-picker-modal.adjust-date-button-label" />
          </Button>
          <Button mt="s100" onClick={onClickCancel} flinkVariant="secondary" size="lg" width="full">
            <FormattedMessage id="components.date-picker-modal.cancel-button-label" />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
