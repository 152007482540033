import { useRef } from "react";

import { Box } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { ItemPickingState } from "flows/Picking/machines/pickingMachine/types";
import { Item } from "flows/Picking/models/item/types";
import { countryNameFromCode } from "libs/countryNames";
import useSwipeScroll from "shared/hooks/useSwipeScroll";
import { SkipIcon, NotFoundIcon } from "ui/Icons/Icons";
import { SideButton } from "ui/SideButton/SideButton";
import { SwipeScrollWrapper } from "ui/SwipeScrollWrapper";
import { preventPropagation } from "utils/domEvents";

import { PickingProductCard, PickingProductCardProps } from "../PickingProductCard";

export type ListItemType = { item: Item } & ItemPickingState;

export type ListItemProps = {
  listItem: ListItemType;
  elementRef?: (element: HTMLDivElement) => void;
  itemIndex: number;
  isSkipped: boolean;
  countryCode: string;
  isAskRestockingButtonDisabled: boolean;
  onResetItemClick?: (itemId: string) => void;
  handleAskForRestocking: () => void;
  handleUpdateItemQuantity: (itemId: string, decrement?: boolean) => void;
} & Pick<
  PickingProductCardProps,
  "onBarcodeClick" | "onSkipItemClick" | "onItemNotFoundClick" | "role"
>;

export function PickingListItem({
  listItem,
  elementRef,
  itemIndex,
  countryCode,
  isAskRestockingButtonDisabled,
  onSkipItemClick,
  onResetItemClick,
  isSkipped,
  handleAskForRestocking,
  handleUpdateItemQuantity,
  ...rest
}: ListItemProps) {
  const intl = useIntl();
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRef = useLayoutStore((state) => state.scrollContainerRef);

  const { handleSwipeScroll } = useSwipeScroll(scrollContainerRef, wrapperRef);
  const { item, state, hasBarcodeIssue, pickedQuantity } = listItem;
  const hasBarCode =
    !hasBarcodeIssue && item.hasSingleUnit && (item.quantity === 1 || pickedQuantity === 0);

  const appLanguage = useAppLanguageStore((languageState) => languageState.appLanguage);

  const countryOfOriginName = item?.countryOfOriginCode
    ? countryNameFromCode(appLanguage, item.countryOfOriginCode.toUpperCase())
    : null;

  const skippedItemDiscriminatedProps: Partial<PickingProductCardProps> = isSkipped
    ? {
        isSkippedItem: true,
        skippedItemPendingStatusLabel: intl.formatMessage({
          id: "components.picking.items-list.skipped-item-status-label-pending",
        }),
        skippedItemRefundStatusLabel: intl.formatMessage({
          id: "components.picking.items-list.skipped-item-status-label-refund",
        }),
        skippedItemBackStockLabel: intl.formatMessage({
          id: "components.picking.items-list.skipped-item-backstock-label",
        }),
        itemNotFoundButtonLabel: intl.formatMessage({
          id: "components.picking.items-list.item-not-found-button-label",
        }),
      }
    : {
        isSkippedItem: false,
        onSkipItemClick,
        skipButtonLabel: intl.formatMessage({
          id: "components.picking.items-list.skip-button-label",
        }),
        onResetClick: onResetItemClick ? () => onResetItemClick(listItem.item.id) : undefined,
        resetButtonLabel: intl.formatMessage({
          id: "components.picking.items-list.reset-button-label",
        }),
      };

  const sideButtonHight = "100%";
  const skipButton = (
    <SideButton
      height={sideButtonHight}
      color="orange.500"
      bgColor="orange.100"
      icon={<SkipIcon boxSize={6} />}
      label={skippedItemDiscriminatedProps.skipButtonLabel ?? ""}
      onClick={preventPropagation(onSkipItemClick)}
      data-testid="skipButton"
    />
  );

  const notFoundButton = (
    <SideButton
      height={sideButtonHight}
      color="blue.500"
      bgColor="blue.100"
      icon={<NotFoundIcon boxSize={6} />}
      label={skippedItemDiscriminatedProps.itemNotFoundButtonLabel ?? ""}
      data-testid="notFoundButton"
      onClick={preventPropagation(rest.onItemNotFoundClick)}
    />
  );

  const selectedButton = skippedItemDiscriminatedProps.isSkippedItem ? notFoundButton : skipButton;

  const hasMissingItems = state === "DONE" && pickedQuantity !== item.quantity;

  const onIncrementItemQuantityClick = () => handleUpdateItemQuantity(item.id);
  const onDecrementItemQuantityClick = () => handleUpdateItemQuantity(item.id, true);
  const handleOnClickProductCard = !hasBarCode
    ? preventPropagation(onIncrementItemQuantityClick)
    : undefined;

  const mainComponent = (
    <PickingProductCard
      mt={itemIndex > 0 ? "s200" : undefined}
      elementRef={elementRef}
      itemId={item.id}
      shelfLetter={item.shelfLetter}
      shelfNumber={item.shelfNumber}
      imageUrl={item.imageUrl}
      name={item.name}
      state={state}
      sku={item.sku}
      pickedQuantity={pickedQuantity}
      totalQuantity={item.quantity}
      availableQuantity={item.availableQuantity}
      handlingUnitSize={item.handlingUnitSize}
      hasMissingItems={hasMissingItems}
      countryOfOriginName={countryOfOriginName}
      hasBarcode={hasBarCode}
      {...skippedItemDiscriminatedProps}
      {...rest}
      onAskForRestocking={handleAskForRestocking}
      shouldShowRestockingDetails
      isAskRestockingButtonDisabled={isAskRestockingButtonDisabled}
      onClickProductCard={handleOnClickProductCard}
      onIncrementItemQuantityClick={onIncrementItemQuantityClick}
      onDecrementItemQuantityClick={onDecrementItemQuantityClick}
      showSKU={false}
    />
  );

  const scrolledComponent = <Box flex="0 0 137px">{selectedButton}</Box>;
  return (
    <SwipeScrollWrapper
      mainComponent={mainComponent}
      ref={wrapperRef}
      scrolledComponent={state === "PICKING" ? scrolledComponent : undefined}
      handleSwipeScroll={handleSwipeScroll}
      showSwipeButtons
    />
  );
}
