import { useRef } from "react";

import { Button, Flex, IconButton } from "@chakra-ui/react";
import { isEqual } from "lodash";
import { useIntl } from "react-intl";
import { Outlet, useNavigate } from "react-router";

import { PageName } from "analytics/events";
import { routes } from "config/routes";
import { HEIGHT } from "core/components/Header";
import { useAppBackgroundEffectsStore } from "core/stores/useAppBackgroundEffectsStore";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { EppoFeatureFlags } from "core/types/flags";
import { Page } from "shared/components/Page";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { AscDscIcon } from "ui/AscDscIcon/AscDscIcon";
import { FileBlankListIcon, FileBlankLockIcon, MagnifyIcon } from "ui/Icons/Icons";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { TabList } from "ui/Tabs/TabList";
import { TabPanel } from "ui/Tabs/TabPanel";
import { TitleXS } from "ui/Typography/Typography";

import { usePrivateRestockingList } from "../../services/restockingService/service";
import { useRestockingProductSearchStore } from "../../stores/restockingProductSearchStore";
import { useRestockingServiceStore } from "../../stores/restockingServiceStore";

export function RestockingListPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { pageName, withTabList } = useLayoutStore((state) => ({
    pageName: state.pageName,
    withTabList: state.withTabList,
  }));
  const isPrivateListPage = pageName === PageName.INVENTORY_PAGE_PRIVATE_LIST;
  const isPublicListPage = pageName === PageName.INVENTORY_PAGE_PUBLIC_LIST;
  const isMoveItemsPage = pageName === PageName.INVENTORY_PAGE_MOVE_ITEMS;

  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const { isFeatureEnabled: isNewRestocking } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.NEW_RESTOCKING,
  );
  const publicRestockingList =
    useAppBackgroundEffectsStore((state) => state.publicRestockingList)?.getPublicRestockingList
      .publicRestockingList.restockingItems.length ?? 0;
  const {
    isLoadingPrivateRestockingList,
    translatedRestockingItems,
    filteredTranslatedRestockingItems,
  } = usePrivateRestockingList();
  const restockingItemsSku = filteredTranslatedRestockingItems?.map((item) => item.sku) || [];
  const {
    selectedItemsBeingRemoved,
    publicListSortState,
    updateSelectedItemsForRemoval,
    togglePublicListSortState,
  } = useRestockingServiceStore((state) => ({
    selectedItemsBeingRemoved: state.selectedItemsBeingRemoved,
    publicListSortState: state.publicListSortState,
    updateSelectedItemsForRemoval: state.updateSelectedItemsForRemoval,
    togglePublicListSortState: state.togglePublicListSortState,
  }));

  const setInitialSearchQuery = useRestockingProductSearchStore(
    (state) => state.setInitialSearchQuery,
  );

  const goToTextSearch = () => {
    setInitialSearchQuery(null);
    navigate(routes.inventory.restockingList.textSearch);
  };

  const tabs = [
    {
      label: intl.formatMessage({
        id: "flows.inventory.restocking-list-pages.navigation-tab.private-list",
      }),
      icon: <FileBlankLockIcon />,
      count: translatedRestockingItems?.length ?? 0,
      isActive: isPrivateListPage,
      onClick: () => navigate(routes.inventory.restockingList.root),
    },
    {
      label: intl.formatMessage({
        id: "flows.inventory.restocking-list-pages.navigation-tab.shared-list",
      }),
      icon: <FileBlankListIcon />,
      count: publicRestockingList,
      isActive: isPublicListPage,
      onClick: () => navigate(routes.inventory.restockingList.publicList),
    },
  ];

  if (!isNewRestocking) return <Outlet />;

  if (isNewRestocking && !withTabList) return <Outlet />;

  return (
    <Page isFull isBgGrey height="100%">
      <NavigationHeader
        title={intl.formatMessage({
          id: "flows.inventory.restocking-list-pages.navigation-header",
        })}
        onClickGoBack={() =>
          navigate(isMoveItemsPage ? routes.inventory.restockingList.root : routes.inventory.root)
        }
        rightElement={
          isPublicListPage ? (
            <IconButton
              size="xs"
              variant="ghost"
              aria-label="Change sort order"
              icon={
                <AscDscIcon height="24px" width="24px" sortState={publicListSortState} bg="white" />
              }
              onClick={() => togglePublicListSortState()}
              data-testid="asc-dsc-icon"
            />
          ) : (
            <IconButton
              size="xs"
              variant="ghost"
              aria-label="Go to text search"
              icon={<MagnifyIcon color="grey.700" h="24px" w="24px" />}
              onClick={goToTextSearch}
              data-testid="go-to-seach-icon"
            />
          )
        }
        border="none"
      />
      <Flex w="100%" h="100%" maxH={`calc(100% - ${HEIGHT})`} flexDirection="column">
        {isMoveItemsPage ? (
          <Flex
            alignItems="center"
            bg="white"
            h="36px"
            w="100%"
            borderBottomWidth="1px"
            borderBottomColor="grey.200"
            px="s200"
          >
            {selectedItemsBeingRemoved.length > 0 ? (
              <Button
                onClick={() => updateSelectedItemsForRemoval([])}
                h="24px"
                borderRadius="24px"
                bg="white"
                px="s150"
                py="s50"
                colorScheme="pinkFlink"
                borderColor="pinkFlink.500"
                variant="outline"
                data-testid="select-all-item-tab"
              >
                <TitleXS color="pinkFlink.500">
                  {intl.formatMessage(
                    {
                      id: "flows.inventory.restocking-list-pages.navigation-tab.selected-items",
                    },
                    { amount: selectedItemsBeingRemoved.length },
                  )}
                </TitleXS>
              </Button>
            ) : null}
            <Button
              onClick={() => {
                if (!isEqual(selectedItemsBeingRemoved, restockingItemsSku)) {
                  updateSelectedItemsForRemoval(restockingItemsSku);
                }
              }}
              h="24px"
              borderRadius="24px"
              px="s150"
              py="s50"
              ml="auto"
              colorScheme="pinkFlink"
              variant="solid"
            >
              <TitleXS color="white">
                {intl.formatMessage({
                  id: "flows.inventory.restocking-list-pages.navigation-tab.select-items",
                })}
              </TitleXS>
            </Button>
          </Flex>
        ) : (
          <TabList
            tabs={tabs}
            activeTabHighlightPosition={tabs.findIndex((tab) => tab.isActive)}
            highlightColor="pinkFlink.500"
            {...(restockingItemsSku.length > 0
              ? {
                  onActionClick: () => navigate(routes.inventory.restockingList.moveItems),
                  actionLabel: intl.formatMessage({
                    id: "flows.inventory.restocking-list-pages.navigation-tab.move-items",
                  }),
                }
              : {})}
            maxW={123}
            minW={123}
          />
        )}
        <TabPanel scrollContainerRef={scrollContainer} isLoading={isLoadingPrivateRestockingList}>
          <Outlet />
        </TabPanel>
      </Flex>
    </Page>
  );
}
