import { useEffect, useRef } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Box, Flex } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import shallow from "zustand/shallow";

import { config } from "config";
import { ConnectivityBanner } from "core/components/ConnectivityBanner";
import { Header } from "core/components/Header";
import { Menu } from "core/components/Menu";
import { NewOrderBanner } from "core/components/NewOrderBanner";
import { TabBar } from "core/components/TabBar";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { useAppPWAStateStore } from "core/stores/useAppPWAStateStore";
import { useEmployeeStore } from "core/stores/useEmployeeStore";
import { useLayoutStore } from "core/stores/useLayoutStore";
import { EppoFeatureFlags } from "core/types/flags";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { useHubSlug } from "shared/hooks/useHubSlug";
import { Spinner } from "ui/Spinner/Spinner";

import { OngoingTimedActivityModal } from "../../shared/components/OngoingTimedActivityModal/OngoingTimedActivityModal";
import { OngoingTimedActivityBanner } from "./OngoingTimedActivityBanner/OngoingTimedActivityBanner";
import { UpdateAppModal } from "./UpdateAppModal";

export function AppLayout() {
  const { isAuthenticated } = useAuth0();
  const { isIdentified, badgeNo } = useEmployeeStore((state) => ({
    isIdentified: state.isIdentified,
    badgeNo: state.badgeNo,
  }));
  const { isFeatureEnabled: isIntercomEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.INTERCOM,
  );
  const hubSlug = useHubSlug();
  const shouldUpdateIntercom = isAuthenticated && isIdentified && isIntercomEnabled;
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const { setAppLayout, withAppHeader, withScrollableAppHeader, canShowUpdateModal, isUpdating } =
    useLayoutStore(
      (state) => ({
        setAppLayout: state.setAppLayout,
        withAppHeader: state.withAppHeader,
        withScrollableAppHeader: state.withScrollableAppHeader,
        canShowUpdateModal: state.canShowUpdateModal,
        isUpdating: state.isUpdating,
      }),
      shallow,
    );

  const appUpdateAvailable = useAppPWAStateStore((state) => state.appUpdateAvailable);

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const wasUpdateAppModalShown = useRef(false);

  useEffect(() => {
    setAppLayout({ scrollContainerRef });
    return () => {
      setAppLayout({ scrollContainerRef: { current: null } });
    };
  }, [setAppLayout]);

  useEffect(() => {
    wasUpdateAppModalShown.current = false;
  }, [appUpdateAvailable]);

  useEffect(() => {
    if (wasUpdateAppModalShown.current || !canShowUpdateModal || !appUpdateAvailable) {
      return;
    }
    wasUpdateAppModalShown.current = true;
    setAppLayout({ showUpdateAppModal: true });
  }, [canShowUpdateModal, appUpdateAvailable, setAppLayout]);

  useEffect(() => {
    if (shouldUpdateIntercom) {
      window.Intercom("update", {
        app_id: config.environment.INTERCOM_APP_ID,
        user_id: badgeNo,
        hub_slug: hubSlug,
        language_override: appLanguage,
        country_iso: hubSlug?.split("_")[0],
        env: process.env.NODE_ENV,
      });
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateIntercom]);

  if (isUpdating) {
    return (
      <Flex justifyContent="center" alignItems="center" width="100%" height="100%">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex justifyContent="center" width="100%" height="100%">
      <Flex flex={1} direction="column" height="100%" maxWidth="container.lg" width="100%">
        <ConnectivityBanner />
        {isAuthenticated && withAppHeader && <Header />}
        <OngoingTimedActivityBanner />
        <NewOrderBanner />
        <Box
          position="relative"
          flex={1}
          height="100vh"
          overflowY={withScrollableAppHeader ? undefined : "scroll"}
          ref={scrollContainerRef}
        >
          <Outlet />
          <Menu />
        </Box>
        <TabBar />
        <OngoingTimedActivityModal />
        <UpdateAppModal />
      </Flex>
    </Flex>
  );
}
