import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Flex } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { InboundListVerificationNoChecks } from "flows/Inbound/components/InboundListVerificationNoChecks";
import { PreDroppingInboundUnitListItem } from "flows/Inbound/components/PreDroppingInboundUnitListItem";
import { StartDroppingButton } from "flows/Inbound/components/StartDroppingButton";
import { useSelectActiveListVerificationChecks } from "flows/Inbound/hooks/useInboundMachineSelectors";
import { useInboundService } from "flows/Inbound/hooks/useInboundService";
import { useInboundUIStore } from "flows/Inbound/stores/useInboundUIStore";
import { Page } from "shared/components/Page";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { Banner } from "ui/Banner/Banner";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { TitleM } from "ui/Typography/Typography";
import { preventScroll } from "utils/domEvents";

const LIST_VERIFICATION_COMPLETE_TOAST_ID = "list_verification_complete_toast";

export function InboundListVerificationPage() {
  const navigate = useNavigate();
  const intl = useIntl();
  const { showToastUI } = useCustomToast();

  const inboundService = useInboundService();

  const setInboundUIState = useInboundUIStore((state) => state.setInboundUIState);
  const checks = useSelectActiveListVerificationChecks();

  const bannersRef = useRef<HTMLDivElement>(null);
  const listScrollContainerRef = useRef<HTMLDivElement>(null);

  const [currentCheckIndex, setCurrentCheckIndex] = useState(0);
  const [areEffectsEnabled, setAreEffectsEnabled] = useState(false);

  const selectCheckWithIndex = useCallback(
    (index: number) => {
      setCurrentCheckIndex(index);
      setInboundUIState({ skuToFocusQuantityInputFor: checks[index].sku });
      if (bannersRef.current && bannersRef.current.children[index]) {
        bannersRef.current.scrollTo({
          left: (bannersRef.current?.children[index] as HTMLElement).offsetLeft,
          behavior: "smooth",
        });
      }
    },
    [checks, setCurrentCheckIndex, setInboundUIState],
  );

  const completeCheck = useCallback(
    (checkKey: string) => {
      inboundService.send({
        type: "COMPLETE_LIST_VERIFICATION_CHECK",
        key: checkKey,
        index: currentCheckIndex,
      });
      if (currentCheckIndex < checks.length - 1) {
        selectCheckWithIndex(currentCheckIndex + 1);
      }
    },
    [currentCheckIndex, checks, inboundService, selectCheckWithIndex],
  );

  const checksCompleted = useMemo(() => {
    return currentCheckIndex === checks.length - 1 && checks[currentCheckIndex].isCompleted;
  }, [currentCheckIndex, checks]);

  useEffect(() => {
    if (checksCompleted && areEffectsEnabled) {
      showToastUI({
        id: LIST_VERIFICATION_COMPLETE_TOAST_ID,
        status: "success",
        containerStyle: {
          alignItems: "center",
          marginBottom: "4.5rem",
        },
        title: "pages.inbound.list-verification.completed-toast",
      });
    }
  }, [checksCompleted, areEffectsEnabled, showToastUI]);

  useEffect(() => {
    // This effect runs on page load. When you click the Android back button from the dropping list
    // and you had some checks to complete beforehand, you end up on this page for a split second.
    // In order to prevent any of the effects (like focussing input fields & scrolling etc.) to run,
    // we only allow then to be executed once this condition has passed.
    if (checks.length > 0 && checks.some((check) => !check.isCompleted)) {
      setAreEffectsEnabled(true);
      const indexOfFirstNonCompletedCheck = checks.findIndex((check) => !check.isCompleted);
      if (indexOfFirstNonCompletedCheck >= 0) {
        selectCheckWithIndex(indexOfFirstNonCompletedCheck);
      } else {
        selectCheckWithIndex(checks.length - 1);
      }
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const bannersRefAtTheTime = bannersRef.current;
    bannersRefAtTheTime?.addEventListener("wheel", preventScroll, { passive: false });
    bannersRefAtTheTime?.addEventListener("touchmove", preventScroll, { passive: false });
    return () => {
      bannersRefAtTheTime?.removeEventListener("wheel", preventScroll);
      bannersRefAtTheTime?.removeEventListener("touchmove", preventScroll);
    };
  }, [bannersRef]);

  return (
    <Page h="100%" isFull isBgGrey data-testid="inbound-list-verification-page">
      <NavigationHeader
        title={intl.formatMessage({ id: "pages.inbound.list-verification.title" })}
        onClickGoBack={() => {
          navigate(-1);
        }}
        rightElement={
          checks.length > 0 && <TitleM>{`${currentCheckIndex + 1}/${checks.length}`}</TitleM>
        }
      />
      {checks.length === 0 ? (
        <InboundListVerificationNoChecks />
      ) : (
        <Flex direction="column" h="100%" w="100%">
          <Flex
            zIndex={1}
            overflowX="scroll"
            ref={bannersRef}
            css={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {!checksCompleted &&
              checks.map((check) => (
                <Banner
                  key={check.key}
                  title={intl.formatMessage({ id: check.title })}
                  description={intl.formatMessage(...check.description)}
                  minW="100%"
                  mb="s100"
                  descriptionSize="s"
                />
              ))}
          </Flex>
          <Flex
            direction="column"
            pos="relative"
            gap="s150"
            overflowY="scroll"
            w="100%"
            h="288px"
            ref={listScrollContainerRef}
            css={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {checks.map((check) => (
              <PreDroppingInboundUnitListItem
                key={check.key}
                sku={check.sku}
                showSwipeButtons={false}
                allowBlur={false}
                defaultTabBarState={false}
                onSubmitQuantityChange={() => completeCheck(check.key)}
                customScrollContainerRef={listScrollContainerRef}
              />
            ))}
          </Flex>
        </Flex>
      )}
      {(checksCompleted || checks.length === 0) && <StartDroppingButton />}
    </Page>
  );
}
