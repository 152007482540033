import { createRef, useEffect, useRef } from "react";

import { isNotNullNorUndefined } from "utils/tsHelpers";

export function useListScrollEffects({
  activeItemId,
  listItemIds,
  scrollOffset = 12,
}: {
  activeItemId: string | null;
  listItemIds: string[];
  scrollOffset?: number;
}) {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<React.RefObject<HTMLDivElement>[]>([]);

  useEffect(() => {
    itemsRef.current = listItemIds.map(
      (_, i) => itemsRef.current[i] || createRef<HTMLDivElement>(),
    );
  }, [listItemIds]);

  useEffect(() => {
    const activeItemIndex = listItemIds.findIndex((id) => id === activeItemId);
    const activeItemIndexElementRef = itemsRef.current?.[activeItemIndex];
    // auto scroll to next active item
    if (
      isNotNullNorUndefined(activeItemIndexElementRef?.current) &&
      isNotNullNorUndefined(scrollContainerRef.current)
    ) {
      scrollContainerRef.current.scrollTo({
        top: activeItemIndexElementRef.current.offsetTop - scrollOffset,
        behavior: "smooth",
      });
    }
  }, [activeItemId, listItemIds, scrollOffset]);

  return {
    scrollContainerRef,
    itemsRef,
  };
}
