import { FormattedMessage, useIntl } from "react-intl";

import { CtaModal } from "ui/CtaModal";
import { WarningThreeIcon } from "ui/Icons/Icons";
import { BodyM } from "ui/Typography/Typography";

import { useFetchClaimedProducts } from "../stores/useInboundDropService";

export function RefetchClaimedListConfirmationModal() {
  const intl = useIntl();
  const { fetchClaimedProducts } = useFetchClaimedProducts();
  const handleOnclick = async () => {
    await fetchClaimedProducts();
  };
  return (
    <CtaModal
      isOpen
      onClose={() => {}}
      hideCloseButton
      onClickCta={handleOnclick}
      headerIcon={<WarningThreeIcon boxSize="80px" />}
      title={intl.formatMessage({
        id: "flows.inbound.components.refetch-claimed-list-confirmation-modal.title",
      })}
      ctaLabel={intl.formatMessage({ id: "generic.actions.refresh" })}
      body={
        <BodyM textAlign="center">
          <FormattedMessage id="flows.inbound.components.refetch-claimed-list-confirmation-modal.body" />
        </BodyM>
      }
    />
  );
}
