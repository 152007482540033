import { ReactElement } from "react";

import { Flex, Box, Button, FlexProps } from "@chakra-ui/react";

import { TitleXS } from "ui/Typography/Typography";

import { TabButton } from "./TabButton";

interface Tab {
  label: string;
  icon: ReactElement;
  count?: number;
  isActive: boolean;
  onClick: () => void;
}

interface TabListProps extends FlexProps {
  tabs: Tab[];
  highlightColor?: string;
  activeTabHighlightPosition?: number;
  onActionClick?: () => void;
  actionLabel?: string;
  maxW: number;
  minW: number;
}

const TabListHeight = "36px";

export function TabList({
  tabs,
  highlightColor = "pinkFlink.500",
  activeTabHighlightPosition = 0,
  onActionClick,
  actionLabel,
  maxW,
  minW,
  ...flexProps
}: TabListProps) {
  return (
    <Flex
      alignItems="center"
      bg="white"
      h={TabListHeight}
      w="100%"
      borderBottomWidth="1px"
      borderBottomColor="grey.200"
      px="s200"
      position="relative"
      {...flexProps}
    >
      <Flex flex="1">
        {tabs.map((tab) => (
          <TabButton
            key={tab.label}
            onClick={tab.onClick}
            isActive={tab.isActive}
            icon={tab.icon}
            label={tab.label}
            count={tab.count}
            maxW={maxW}
            minW={minW}
          />
        ))}
      </Flex>
      <Box
        position="absolute"
        bottom="0"
        left={`${activeTabHighlightPosition * minW}px`}
        height="2px"
        bg={highlightColor}
        maxW={`${maxW}px`}
        minW={`${minW}px`}
        borderRadius="1px"
        transition="left 0.3s ease"
        mx="s200"
      />
      {onActionClick && actionLabel && (
        <Button
          onClick={onActionClick}
          h="fit-content"
          maxH={TabListHeight}
          borderRadius="24px"
          bg={highlightColor}
          color="white"
          p="s50"
          ml="auto"
          data-testid="action-tab"
          maxW={`${maxW}px`}
          whiteSpace="normal"
          wordBreak="break-word"
        >
          <TitleXS color="white">{actionLabel}</TitleXS>
        </Button>
      )}
    </Flex>
  );
}
