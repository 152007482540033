import React, { useCallback, useRef, useState } from "react";

import { InputProps } from "@chakra-ui/react";

import { useScan } from "shared/hooks/useScan/useScan";

const isManualKeyboardEvent = (key: string) => {
  // if this code run in a browser ither this check
  const isAndroid = /(android)/i.test(navigator.userAgent);
  // Should be one char string or number
  const isSingleCharOrInt = key.length > 0 || !Number.isNaN(parseInt(key, 10));
  return isSingleCharOrInt || !isAndroid;
};

export type InputOptions = {
  inputMode?: "text" | "numeric";
  inputName?: string;
  onValueChange: (value: string) => void;
  onScan: (value: string) => void;
  onKeyboardChange: (value: string) => void;
  isResetable: boolean;
  isAutoFocus: boolean;
  showKeyBoard: boolean;
  preventKeyboard?: boolean;
};

export function useScannableInput(
  inputRef: React.RefObject<HTMLInputElement>,
  {
    onValueChange,
    onScan,
    onKeyboardChange,
    isAutoFocus,
    showKeyBoard,
    isResetable,
    inputMode,
    inputName,
    preventKeyboard = false,
  }: InputOptions,
) {
  const isKeyboard = useRef(false);
  const defalutisInputModeEnabled = showKeyBoard || !isAutoFocus;
  const [isInputModeEnabled, setIsInputModeEnabled] = useState(defalutisInputModeEnabled);

  const [value, setValue] = useState("");
  const canReset = isResetable && value !== "";

  useScan({
    onScan: (scannedValue) => {
      if (document.activeElement === inputRef.current) {
        setValue(scannedValue);
        onValueChange(scannedValue);
        onScan(scannedValue);
      }
    },
  });

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isKeyboard.current === true) {
        setValue(event.target.value);
        onValueChange(event.target.value);
        onKeyboardChange(event.target.value);
        isKeyboard.current = false;
      }
    },
    [onValueChange, onKeyboardChange],
  );

  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isManualKeyboardEvent(e.key)) {
      isKeyboard.current = true;
    }
    if (preventKeyboard) {
      e.preventDefault();
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetValue = useCallback(() => {
    setValue("");
    onValueChange("");
    setIsInputModeEnabled(!isAutoFocus);
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onValueChange]);

  const onClick = useCallback(() => {
    setIsInputModeEnabled(true);
  }, []);
  const updateValue = useCallback((newValue: string) => {
    setValue(newValue);
  }, []);

  return {
    inputRef,
    canReset,
    resetValue,
    updateValue,
    inputProps: {
      value,
      name: inputName,
      onKeyDown,
      onChange,
      onClick,
      inputMode: isInputModeEnabled ? inputMode : "none", // This handle the folowing scenario : Input should be focus but with no keyboard open
      autoFocus: isAutoFocus,
      type: "text",
      variant: "filled",
      readOnly: preventKeyboard,
    } as InputProps,
  };
}
